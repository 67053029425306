.sectionName {
    display: flex;
    max-height: 40px;
    width: 130px;
  }
  .sectionNameSelected{
    display: flex;
    max-height: 50px;
    width: 130px;
    background-color: #97C8F6;
  }
  .ant-menu-item:focus {
    background-color: transparent;
  }
  .tabNameSelected {
    color: #123A79;
    font-size: 24px;
  }
  .tabNameNotSelected {
    color: #8C8C8C;
    font-size: 20px;
  }
  .video{
    width: 100%;
    height: 600px;
    margin-top: 12px;
  }
  /* .helpDrwaer .ant-drawer-content-wrapper, .ant-drawer-wrapper-body, .ant-drawer-content{
    width: 500px !important;
  } */


  @media only screen and (max-device-width: 380px) {
    .sectionName {
      width: 109px;
    }
    .tabNameSelected {
      font-size: 20px;
    }
    .tabNameNotSelected {
      font-size: 16px;
    }
  }
  @media all and (min-width:480px) and (max-width:790px){
    .video {
      height: 300px;
    }
  }
  @media all and (max-width: 480px) {
    .video {
      height: 200px;
    }
  }
