@media print {
    .ant-typography-copy {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
    .special-label,
    .flag-dropdown {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
}
