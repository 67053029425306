.errorMessage {
  font-size: 26px;
  font-weight: semi-bold;
  margin-bottom: 0px;
  max-width: 70vw;
  margin: auto;
}
@media only screen and (max-device-width: 480px) {
  .errorMessage {
    font-size: 15px;
    font-weight: semi-bold;
  }
}
