.createHelpForm .label:hover {
    background-color: none;
    color: initial;
  }

.label {
    display: block;
    padding: 5px 10px;
    cursor: pointer;
  }

 .inputtag[type="checkbox"] {
    margin-right: 5px;
    cursor: pointer;
  }

  /* Style the "Select All" label differently */
.inputtag[type="checkbox"]:first-child + label {
    font-weight: bold;
    color: #333;

  }

  /* Style selected options */
.label input[type="checkbox"]:checked + label {
    background-color: #f0f0f0;
  }

  /* Hover effect for options */
.label:hover {
    background-color: #e0e0e0;
  }

  .createHelpForm .ant-form-item-label {
    margin-bottom: -9px;
  }

.createHelpForm .ant-form-item-control-input {
    margin-bottom: -18px;
  }
