.site_layout_background {
  background: #fff;
  /* padding: "24px"; */
  /* min-height: "100vh"; */
}

.cardSettings {
  margin: 0 5px 5px 5px !important;
  max-width: 100% !important;
  position: relative !important;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff !important;
  background-clip: border-box !important;
  border: 1px solid #e3e6f0 !important;
  border-radius: 0.35rem !important;
  flex: 1 1 auto;
  min-height: 1px;
  /* padding: 1.25rem !important; */
  border-left: 0.25rem solid #4e73df !important;
}

.cardSettingsSecond {
  border-left: 0.25rem solid #815ba5 !important;
}

.cardSettingsThird {
  border-left: 0.25rem solid #5ba575 !important;
}

.cardSettingsFourth {
  border-left: 0.25rem solid red !important;
}

.genrateButton {
  display: flex !important;
  justify-content: flex-end;
  padding-right: 8px;
}

.genrateButtonTextChange {
  content: "Genrate Reports" !important;
}

@media (min-width: 320px) {
  .genrateButtonTextChange {
    content: "" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 480px) {
  .genrateButtonTextChange {
    content: "" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 600px) {
  .genrateButtonTextChange {
    content: "" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 801px) {
  .genrateButtonTextChange {
    content: "Genrate Reports" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 1025px) {
  .genrateButtonTextChange {
    content: "Genrate Reports" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
@media (min-width: 1281px) {
  .genrateButtonTextChange {
    content: "Genrate Reports" !important;
    border-radius: 0.25rem !important;
    background-color: rgb(78, 115, 223) !important;
  }
}
