.contentDiv {
    margin-top: -20px;
    padding: 10px;
    min-height: 60vh;
    max-height: 60vh;
    overflow-y: auto;
    /* max-height: 60vh;
    /* overflow: auto; */ 
}
.personheading{
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    font-size: 20px;
    width:100%;
    color: #1890ff;
    cursor:"pointer"

}
.corpheading{
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    font-size: 20px;
    margin-top:-5px;
    width:100%;
    color: #1890ff;


}
.formItem {
    margin: 0;
    width: 100%;
}
.inputBoxDiv {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.selectedRowsOuterDivParent {
    width: 100%;
}
.selectedRowsOuterDiv {
 overflow: hidden;
 margin-left: -10px;
 margin-bottom: -20px;/* Set maximum height */
}

.infoText{
    text-align: center;
    color: #0707ff;
    margin: -4px 0 20px 0;
    font-size: 15px;
}

@media screen and (max-width: 500px) {
    .contentDiv {
        padding: 5px;
    }
    .formItem {
        margin: 0%;
    }
    .inputBoxDiv input{
        margin-left: 5px;
    }
    .selectedRowsOuterDiv {
        max-height: 500px;
        font-size: small;
    }
    .infoText{
        text-align: left;
    }
}
