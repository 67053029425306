.signOfferConfirmationBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 5px;
  margin: 50px 20px 20px 20px;
  text-align: center;
}

.signOfferConfirmationMsgDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 20px;
  height: 80%;
  font-family: Arial, Helvetica, sans-serif;
}

.eachDocumentRowContainer {
    width: 100%;
    position: sticky;
    display: flex;
    align-self: center;
    border-style: solid;
    border-width: 0 8px 0 8px;
    border-right-color: #ffce51;
    border-left-color: #3376a8;

    /* style={{
        position: "sticky",
        borderStyle: "solid",
        borderRightWidth: 8,
        borderLeftWidth: 8,
        borderBottomWidth: 0,
        borderTopWidth: 0,
        borderRightColor: "#ffce51",
        borderLeftColor: "#3376a8",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignSelf: "center",
      }} */
}

.sellerCompContainer {
    /* flex: 1; */
    justify-content: space-between;
    text-align: center;
    align-items: center;
    width: 100%;

    /* style={{
        flex: 1,
        justifyContent: "space-between",
        textAlign: "center",
        alignItems: "center",
        marginBottom: 0,
      }} */
}
.sellerCompContainer .sellerCenterline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    /* style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 17,
      }} */
}

.buyerCompContainer {
    /* flex: 1; */
    justify-content: space-between;
    text-align: center;
    width: 100%;
}

.buyerCompContainer .buyerCenterLine {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.signalIconOutline {
    width: 33px;
    height: 30px;
    border-radius: 4px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.signalIndicator {
    width: 19px;
    height: 19px;
    position: relative;
    border-radius: 9px;
    overflow: hidden;
}

.fullIndicator {
    width: 19px;
    height: 19px;
    position: relative;
    border-radius: 9px;
    overflow: hidden;
    background-color: var(--fullColor);
}

.signalIndicator::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    background-color: var(--topColor);
}

.signalIndicator::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    border-top: 1.6px solid rgb(42, 42, 42);
    bottom: 0;
    left: 0;
    background-color: var(--bottomColor);
}


.dateAndTimeSeller {
    font-size: 10px;
    text-align: left;
    padding: 5px 0 0 5px;
    color: #292929;
}
.newdateSeller{
    text-align: left;
    margin-left: 10px;
   font-size:13px;
    font-weight:bolder
  
  }
  .newtimeSeller{
   text-align: center;
    margin-left: 10px;
   font-size:13px;
   margin-top: -16px;
  font-weight:bolder;
  margin-bottom: 0,
}
.newdateBuyer{
text-align:end;
margin-right: 10px;
font-size:13px;
font-weight:bolder
}
.newtimeBuyer{
   
text-align: right;
margin-right: 20px;
margin-top: -18px;
font-size:13px;
font-weight:bolder;
margin-bottom: 0,
      }
.dateAndTimeBuyer {
    font-size: 10px;
    text-align: right;
    padding: 5px 5px 0 0;
    color: #292929;
}
.documentDetailOuterCOntainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100px;
}
.documentDetailOuterCOntainer .documentBox {
    /* width: 310px; */
    width: 100%;
    min-height: 50px;
    border: 2px solid;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    color: #8e8e8e;
    font-weight: 200;
    margin: 4px 0 4px 0;
    text-align: center;
    padding: 2px;
   }

.documentNameContainer {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* word-break: break-all; */
}

.documentName {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.documentName:hover{
    color: rgb(8,81,145);
}

@media screen and (min-width: 550px) and (max-width: 720px) {
    .documentName.singleWord {
        max-width: 60px;
    }
    .newdateSeller{
        margin-left: 5px;
      font-size:11px;
    }
    .newtimeSeller{
       margin-left: 5px;
        font-size:11px;
     }
     .newdateBuyer{
         margin-right: 10px;
        font-size:11px;
     }
        .newtimeBuyer{
        margin-right: 20px;
        margin-top: -18px;
        font-size:11px;
       }
}
@media screen and (min-width: 850px) and (max-width: 950px) {
    .documentName.singleWord {
        max-width: 70px;
    }
    .documentName {
        font-size: 12px;
    }
}
@media screen and (min-width: 950px) and (max-width: 1200px) {
    .documentName.singleWord {
        max-width: 100px;
    }
}
@media screen and (max-width: 410px) {
    .documentName.singleWord {
        max-width: 60px;
    }
    .sellerCompContainer {
        /* flex: 1; */
        justify-content: space-between;
        text-align: center;
        align-items: center;
        width: 85%;
    
    }
    .signalIconOutline {
        width: 18px;
        height: 20px;
        border-radius: 2px;

    }
    .fullIndicator {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        /* position: relative;
       
        overflow: hidden;
        background-color: var(--fullColor); */
    }
    .signalIconOutline .signalIndicator {
        width: 10px;
        height: 10px;
        border-radius: 5px;

    }
    .newdateSeller{
        margin-left: 5px;
      font-size:10px;
    }
    .newtimeSeller{
        margin-left: 5px;
      font-size:10px;
     
    }
    .newdateBuyer{
        margin-right: 10px;
       font-size:10px;
    }
       .newtimeBuyer{
       margin-right: 20px;
       margin-top: -16px;
       font-size:10px;
      }
}


@media screen and (min-width: 551px) and (max-width: 850px) {

    .dateAndTimeSeller, .dateAndTimeBuyer {
        font-size: 8px;
    }

    .documentDetailOuterCOntainer .documentBox {
        /* width: 200px; */
        font-size: 12px;
        font-weight: 200;
        width: 100%;
        min-height: 40px;
    }
    .documentName {
        font-size: 12px;
    }
}



@media screen and (min-width: 413px) and (max-width: 550px) {
    
    .signalIconOutline {
        width: 18px;
        height: 21px;
        border-radius: 2px;

    }
    .fullIndicator {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        /* position: relative;
       
        overflow: hidden;
        background-color: var(--fullColor); */
    }
    .signalIconOutline .signalIndicator {
        width: 12px;
        height: 12px;
        border-radius: 6px;

    }

    .dateAndTimeSeller, .dateAndTimeBuyer {
        font-size: 8px;
    }

    .documentDetailOuterCOntainer .documentBox {
        /* width: 120px; */
        font-size: 10px;
        font-weight: 200;
        width: 100%;
        min-height: 35px;

    }
    .documentName {
        font-size: 12px;
    }
    
    .signOfferConfirmationBtns {
      flex-direction: column;
        }
    
    .signOfferConfirmationMsgDiv {
        text-align: start;
    }
    

}
