@media print {
    .noPrint {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
}

.topNavBar {
    width: 100%;
    height: 5vh;
    position: fixed;
    top: 0;
    background-color: #124E8F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
    z-index: 1;
}

.topNavBar .logoContainer {
    width: 100%;
    text-align: left;
}

.topNavBar .logoContainer img {
    width: 80px;
    cursor: pointer;
}

.topNavBar .toolsContainer {
    width: 100%;
    display: flex;
}

.topNavBar .toolsContainer .dummyDiv,
.topNavBar .toolsContainer .printBtnContainer,
.topNavBar .toolsContainer .closeBtnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
}

.topNavBar .toolsContainer .closeBtnContainer .closeBtn,
.topNavBar .toolsContainer .dummyDiv .saveBtn,
.topNavBar .toolsContainer .printBtnContainer .printBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

@media screen and (min-width: 551px) and (max-width: 850px) {

    .topNavBar .logoContainer img {
        width: 70px;
    }
}

@media screen and (max-width: 550px) {

    .topNavBar {
        height: 5vh;
        padding: 1.5em;
    }

    .topNavBar .logoContainer img {
        width: 55px;
    }

    .topNavBar .toolsContainer .closeBtnContainer .closeBtn span,
    .topNavBar .toolsContainer .dummyDiv .saveBtn,
    .topNavBar .toolsContainer .printBtnContainer .printBtn span {
        font-size: 10px;
    }
}