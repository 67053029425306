.preRTDLayoutOuterContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    text-align: center;
    /* outline: auto; */

}

.propertyDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 80%;
    border-radius: 8px;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.addressDiv {
    /* outline: auto; */
    font-weight: bold;
    color: grey;
    font-size: large;
}
.sendEmail {
    font-weight: bold;
    color: grey;
    font-size: 19px;
    margin-top:20px;
    margin-left: 20px;
    cursor:pointer
}
.buyerSellerBox {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buyerSellerBox .sellerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}
.buyerSellerBox .sellerContainer .sellerDesk {
    background: #2976be;
    width: 100%;
    padding: 5px;
    border-radius: 5px 5px 5px 0;
    color: white;
    font-weight: bold;
    font-family: sans-serif;
}

.buyerSellerBox .buyerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;

}

.buyerSellerBox .buyerContainer .buyerDesk {
    background: #ffce51;
    width: 100%;
    padding: 5px;
    border-radius: 5px 5px 0 5px;
    font-weight: bold;
    font-family: sans-serif;
}

.agentDetailsModalContent {
    display: flex;
    align-items: center;
    gap: 30px;
    color: grey;
    margin-bottom: 40px;
}
.agentDetailsModalContent .userDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.agentDetailsModalContent .userDetails .userDetailsFlex {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}
.preRTDDocOuterContainer {
    display: flex;
    flex-direction: column;
    height: 50vh;
}
.preRTDDocOuterContainer ::-webkit-scrollbar {
    display: none !important;
}
.preRTDDocOuterContainer .documentContainer {
    overflow-y: auto;
    min-height: 40vh;
}
.preRTDDocOuterContainer .sharedFolderBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 70%;
    margin: auto;
    border: 2px solid;
    border-radius: 16px;
    color: #096dd9;
    border-color: #2976be;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    background-color: aliceblue;
   

}
.trnxnDiv {
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    outline: auto;
}

.sharedDocModalContents {
    height: 60vh;
}

.sharedDocHeading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #096dd9;
    font-size: 20px;
    font-weight: 500;
}

.sharedDocModalContents .tableOuterScroll {
    height: 50vh;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0 10px;

}
.sharedDocModalContents .tableOuterContainer {
    /* padding: 10px; */
    min-width: 270px;


}
.sharedDocModalContents .spinner {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sharedDocModalContents .sharedFolderTable {
    width: 100%;
    position: relative;
}
.sharedDocModalContents .sharedFolderTable thead{
    background-color: #2171b9;
    color: white;
    text-align: center;
    position: sticky;
    top: 0;
    width: 100%;
    font-size: 16px;
    outline: auto;
}
.sharedDocModalContents .sharedFolderTable thead .FileName {
    text-align: left;
    padding-left: 10px;
}
.sharedDocModalContents .sharedFolderTable td,
.sharedDocModalContents .sharedFolderTable th {
    border: 1px solid #ddd;
}
.sharedDocModalContents .sharedFolderTable .fileNameColumn {
    color: blue;
    cursor: pointer;
    font-weight: bolder;

}
.sharedDocModalContents .sharedFolderTable .sharedDate {
    color: black;
    font-weight: 500;
    text-align: center;
    white-space: pre-wrap;
}
.sharedDocModalContents .sharedFolderTable .sharedByName {
    color: grey;
    font-size: 14px;
    font-weight: 400;
    text-align: center;

}





@media screen and (min-width: 551px) and (max-width: 850px) {

    .preRTDLayoutOuterContainer {
        width: 100%;
    }

    .addressDiv {
        font-size: 14px;
    }
    .sendEmail {
        font-weight: 14px;
        color: grey;
        font-size: large;
        margin-top:20px;
        margin-left: 20px;
    }
    .buyerSellerBox .sellerContainer .sellerDesk,
    .buyerSellerBox .buyerContainer .buyerDesk {
        font-size: 12px;
    }
    .preRTDDocOuterContainer {
        height: 54vh;
    }
    .preRTDDocOuterContainer .documentContainer {
        min-height: 50vh;
    }
    .preRTDDocOuterContainer .sharedFolderBtn {
        border-radius: 14px;
        font-weight: 500;
        font-size: 14px;
        gap: 5px;
    }
    
    .sharedDocHeading {
        gap: 8px;
        font-size: 16px;
    }
    .sharedDocModalContents .sharedFolderTable .fileNameColumn,
    .sharedDocModalContents .sharedFolderTable thead {
        font-size: 14px;
    }
    .sharedDocModalContents .sharedFolderTable .sharedDate,
    .sharedDocModalContents .sharedFolderTable .sharedByName {
        font-size: 12px;
    }

}

@media screen and (max-width: 550px) {

    .preRTDLayoutOuterContainer {
        width: 100%;
    }

    .propertyDetails {
        gap: 5px;
        
    }

    .addressDiv {
        font-size: small;
    }
    .sendEmail {
        font-size: small;
        margin-top:20px;
        margin-left: 20px;
    }
    .buyerSellerBox .sellerContainer .sellerDesk,
    .buyerSellerBox .buyerContainer .buyerDesk {
        font-size: 12px;
    }
     .preRTDDocOuterContainer {
        height: 58vh;
    }
    .preRTDDocOuterContainer .documentContainer {
        min-height: 51vh;
    }
    .preRTDDocOuterContainer .sharedFolderBtn {
        border-radius: 12px;
        font-weight: 500;
        font-size: 12px;
        gap: 5px;
        margin-bottom: 30px;
    }
    .sharedDocHeading {
        gap: 5px;
        font-size: 14px;
    }
    .sharedDocModalContents .sharedFolderTable .fileNameColumn,
    .sharedDocModalContents .sharedFolderTable thead,
    .sharedDocModalContents .sharedFolderTable .sharedDate,
    .sharedDocModalContents .sharedFolderTable .sharedByName {
        font-size: 12px;
    }


}
