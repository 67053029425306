.agentCard {
    border: 1px solid rgb(198, 198, 198); 
    border-radius: 2px;
    padding: 3px 7px;
    cursor: pointer;
}

.agentCard div{
    white-space: nowrap;
    overflow: hidden;  
    text-overflow: ellipsis;
}

.agentCard:hover {
    background-color: rgb(228, 231, 234);
}

.agentCardParent {
    height: 330px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    color: rgb(84, 84, 84);
    padding-right: 5px;
}

.agentCardParent::-webkit-scrollbar {
    width: 7px; /* Adjust scrollbar width */
}

.filterTitle{
    display: flex; 
    justify-content: center;
    padding: 0px;
    color: rgba(16, 138, 252, 0.989); 
    text-decoration: underline;
    text-underline-offset: 4px;
}

@media screen and (max-width: 550px) {

    .modalDateTitle,
    .modalContents {
        font-size: small;
    }

    .fullAddress {
        font-size: medium;
       
    }

    .eventName {
        font-size: smaller; 
    }

    .description {
        font-size: smaller;
    }
}