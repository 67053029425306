thead[class*="ant-table-thead"] tr th {
  background-color: hsl(211, 75%, 48%) !important;
  font-weight: 500 !important;
  color: white;
 
}

.emailModal {
  top: 30px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 60vh;
}

.emailModal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.emailModal .ant-modal-header {
  flex-shrink: 0;
}

.emailModal .ant-modal-footer {
  flex-shrink: 0;
}

.emailModal .ant-modal-body {
  flex-grow: 1;
  overflow: auto;
}

.emailModal .ant-table {
  margin-bottom: 15px;
}

.emailModal .message-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid grey;
  padding: 10px;
}

.emailModal .message-container .ant-input-textarea {
  height: 100%;
  padding: 10px;
}

@media (min-width: 800px) {
  .emailModal .message-container {
    overflow: auto;
  }
  .emailModal {
    min-width: 600px;
    height: 80vh;
  }
}
@media (min-width: 1000px) {
  .emailModal {
    min-width: 800px;
    height: 90vh;
  }
}
