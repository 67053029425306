#NDAFormContainer .page {
    background-color: white;
    position: relative;
    z-index: 0;
  }
  
  @media (min-width: 601px) {
   #NDAFormContainer .text {
      position: absolute;
      z-index: 11;
      opacity: inherit;
      white-space: nowrap;
      font-size: medium;
    }
  }
  @media (max-width: 600px) {
#NDAFormContainer .text {
      position: absolute;
      z-index: 11;
      opacity: inherit;
      white-space: nowrap;
      font-size: small;
    }
  }
  
  #NDAFormContainer .control {
    position: absolute;
    z-index: 13;
  }

  #NDAFormContainer .signaturealighnment {
    height: 55px;
  }
  #NDAFormContainer .signatureDivAlighnment {
    position: absolute;
    padding: 0 !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    left: 96.8px;
    top: -23.7px;
    width: 283px;
    z-index: 12;
    border: 1px solid #b6dfdc;
    background-color: #b6dfdc;
    color: rgb(117, 117, 117);
    height: 72px;
    border-radius: 7px;
    box-shadow: rgb(188 184 184) 0px 3px 6px;
  }
  #NDAFormContainer .signatureResureAlighnment {
    background: #95d3ce;
    color: darkblue;
  }

  

