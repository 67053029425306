.layoutAlignment {
  padding: 0px;
  position: absolute;
  left: 54%;
  top: 12%;
  transform: translate(-50%);
}

.contentAlignment {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.cardAlignment {
  width: 400px;
}

.buttonAlignment {
  text-align: center;
}

@media (min-width: 280px) {
  .layoutAlignment {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 13%;
    transform: translate(-50%);
  }

  .contentAlignment {
    position: absolute;
    left: 50%;
    transform: translate(-54%);
  }

  .cardAlignment {
    width: 250px;
    height: 75vh;
  }
}

@media (min-width: 320px) {
  .layoutAlignment {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 17%;
    transform: translate(-50%);
  }

  .contentAlignment {
    position: absolute;
    left: 50%;
    transform: translate(-54%);
  }

  .cardAlignment {
    width: 280px;
    height: 70vh;
  }
}
@media (min-width: 480px) {
  .layoutAlignment {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 17%;
    transform: translate(-50%);
  }

  .contentAlignment {
    position: absolute;
    left: 50%;
    transform: translate(-54%);
  }

  .cardAlignment {
    height: 70vh;
    width: 340px;
  }
}
@media (min-width: 600px) {
  .layoutAlignment {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 12%;
    transform: translate(-50%);
  }

  .contentAlignment {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .cardAlignment {
    /* width: 400px; */
    /* height: 70vh; */
    /* height: 50vh; */
    width: 250px;
    height: 75vh;
  }
  .buttonAlignment {
    text-align: end;
  }
}
@media (min-width: 801px) {
  .layoutAlignment {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 12%;
    transform: translate(-50%);
  }

  .contentAlignment {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .cardAlignment {
    /* width: 400px;
    height: 50vh; */
    width: 250px;
    height: 75vh;
  }
  .buttonAlignment {
    text-align: end;
  }
}
@media (min-width: 1025px) {
  .layoutAlignment {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 12%;
    transform: translate(-50%);
  }

  .contentAlignment {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .cardAlignment {
    width: 400px;
    height: 56vh;
  }
  .buttonAlignment {
    text-align: end;
  }
}
@media (min-width: 1281px) {
  .layoutAlignment {
    padding: 0px;
    position: absolute;
    left: 54%;
    top: 12%;
    transform: translate(-50%);
  }

  .contentAlignment {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }

  .cardAlignment {
    width: 400px;
    height: 56vh;
  }

  .buttonAlignment {
    text-align: end;
  }
}
