@media screen and (max-width: 900px) {
    .resetBtn {
        width: 110px !important;
        height: 30px !important;
        box-sizing: border-box !important;
        padding: 0px !important;
        margin-left: 50px !important;
        margin-top: 3px !important;
        text-align: center !important;
        font-size: 5px !important;
        font-weight: 100;
      }
}

@media screen and (max-width: 680px){
    .resetBtn {
        position: relative;
        top: 17px;
        left: -120%;
        width: 110px !important;
        height: 30px !important;
        box-sizing: border-box !important;
        padding: 0px !important;
        margin-left: 30px !important;
        margin-top: 3px !important;
        text-align: center !important;
        font-size: 5px !important;
        font-weight: 100;
      }
}

@media screen and (max-width: 500px){
    .resetBtn {
        position: relative;
        top: 17px;
        left: -110%;
        width: 110px !important;
        height: 30px !important;
        box-sizing: border-box !important;
        padding: 0px !important;
        margin-left: 30px !important;
        margin-top: 3px !important;
        text-align: center !important;
        font-size: 5px !important;
        font-weight: 100;
      }
}
@media screen and (max-width: 350px){
    .resetBtn {
        position: relative;
        top: 17px;
        left: -100%;
        width: 110px !important;
        height: 30px !important;
        box-sizing: border-box !important;
        padding: 0px !important;
        margin-left: 30px !important;
        margin-top: 3px !important;
        text-align: center !important;
        font-size: 5px !important;
        font-weight: 100;
      }
}

@media (min-width: 801px) {
  .visibilityComponent {
    visibility: hidden !important;
  }
  .visibilityComponentForSmallScreen {
    display: block;
  }
  .visibilityComponentText {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    visibility: visible !important;
  }

  .calendarTool {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
}
@media (max-width: 800px) {
  .visibilityComponent {
    visibility: visible !important;
  }

  .visibilityComponentForSmallScreen {
    display: none;
  }

  .visibilityComponentText {
    /* visibility: hidden !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    visibility: visible !important;
  }
  .calendarTool {
    display: grid;
    justify-content: center;
    text-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.hoverEffect {
  background-color: rgb(11 123 203 / 85%);
  color: white;
}

