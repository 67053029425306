/* #components-layout-demo-responsive  */
.logo {
  /* height: 32px; */
  margin: 16px;
  width: 60px;
  height: auto;
  /* background: rgba(255, 255, 255, 0.2); */
}

.site_layout_sub_header_background {
  background: #fff;
}

.site_layout_background {
  background: #fff;
}

.menuScrollBox::-webkit-scrollbar {
    display: none;
  }
  
.menuScrollBox .ant-menu-item{
   white-space: normal !important;
  }

.trigger {
  padding: 0 24px;
  font-size: 25px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: rgb(78, 115, 223);
}

.trigger:hover {
  color: rgb(185, 138, 138);
}

@media only screen and (max-width: 375px) {
    .help{
         position: fixed;
         bottom: 20;
         /* right: 20; */
         left:30px;
         z-index: 999;
         width: 350px;
        }
}
