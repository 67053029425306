.planDetailsModalDiv {
  display: flex;
  align-items: center;
  gap: 20px;
}

.planDetailsModalDiv p:first-child {
  font-size: 18px;
  font-weight: 600;
  width: 170px;
}
.planDetailsModalDiv p:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
}
