.mainContainer {
    position: relative;
    overflow: hidden;
}

.preRTDNav {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 4;
}

.preRTD {
    padding: 10px;
    width: 100%;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 60px;

}

.preRTD .RTDSection {
    margin: auto;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-right: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 10px;
    height: calc(100vh - 6rem);
    width: 100%;
    
}

.preRTD .DoraDocumentSection {
    background: white;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    height: calc(100vh - 6rem);
}

@media screen and (min-width: 551px) and (max-width: 850px) {
    
    .preRTD {
        margin-top: 55px;
    }
}

@media screen and (max-width: 550px) {
    .outerSectionScroll {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .mainContainer .preRTD {
        width: 150%;
        margin-top: 50px;
    }

    .preRTD .RTDSection {
        width: 100%;
    }

    .preRTD .DoraDocumentSection {
        width: 50%;
    }
}