@media print {

  .calendarWrapperContainer .calendarWrapper .printTitleAndPropertyAddressCont {
    display: block !important;
    text-align: center !important;
  }
  .calendarWrapperContainer .calendarWrapper .calendar {
    margin-bottom: 10px !important;
  }

  .calendarWrapperContainer .calendarWrapper .calendar .header {
    padding: 5px 0 !important;
  }

  .calendarWrapperContainer .calendarWrapper .calendar .header .headerTools .arrow {
    display: none;
  }

  .calendarWrapperContainer .calendarWrapper .calendar .weekdays {
    display: flex !important;
  }

  .calendarWrapperContainer .calendarWrapper .calendar .weekday,
  .calendarWrapperContainer .calendarWrapper .calendar .empty,
  .calendarWrapperContainer .calendarWrapper .calendar .day {
    width: calc(100% / 7) !important;
  }

  .calendarWrapperContainer .calendarWrapper .calendar .daysGrid {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .calendarWrapperContainer .viewModeOptions, 
  .calendarWrapperContainer .viewModeOptions .hideOnPrint,
  .printBtn,
  .shareBtn {
    display: none !important;
  }
  .noPrint {
    display: none !important;
  }
  .day {
    /* break-inside: avoid; */
  }

  .day.today {
    background-color: unset !important;
  }

  .calendarWrapperContainer .calendarWrapper .calendar .eventContainer .eventTitle .homeEvent{
    line-height: normal;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }
}

.taskTitle{
  width:100%;
  justify-content: center;
  font-weight: bold;
  display: flex;
}

.cardFullAddress{
  font-weight: 700;
  color: rgb(82, 82, 82); 
  cursor: pointer
}

.cardFullAddress:hover{
  color: black;
}

.cardFullAddressHyperLink{
  font-weight: 700;
  color: blue; 
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 1.5px;
}
.cardFullAddressHyperLink:hover{
  color: #551A8B;
}
.hoverTaskLabel{
  font-weight: bold;
  min-width: 60px;
  color:rgb(82, 82, 82);
}

.calendarWrapperContainer {
  width: 100%;
  position: relative;
  /* margin: 0 5px; */
}

.calendarWrapperContainer .calendarWrapper .printTitleAndPropertyAddressCont {
  display: none;
}

.calendar {
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

.header {
  text-align: center;
  /* font-weight: bold; */
  margin-bottom: 10px;
  background-color: #ffffff;
  /* color: aliceblue; */
  color: #1890FF;
  font-size: 1.75em;
  font-weight: 500;
}

.header .headerTools {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.centerLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.header .headerTools .monthTitle {
  min-width: 200px;
  margin-left: -38px;
  
}

.header .headerTools .arrow {
  cursor: pointer;
}

.calendar .parentGridOutline {
  border: 1px solid silver;
}

.weekdays {
  display: flex;
}

.weekday {
  border: 1px solid silver;
  border-spacing: 0;
  color: #178DFA;
  font-weight: bold;
  background-color: white;
}

.weekday,
.empty {
  width: calc(100% / 7);
  text-align: center;
}

.daysGrid {
  display: flex;
  flex-wrap: wrap;
  /* background-color: #d9d9d99a; /* Make greyish background for days not in month (after days) */
}

.day {
  width: calc(100% / 7);
  border: 1px solid silver;
  border-spacing: 0;
  padding: 5px;
  min-height: 40px;
  background-color: white;
}

.day:hover {
  background-color: aliceblue;
}

.day.today {
  background-color: #fffadf;
}

.day .dayNumber {
  text-align: top;
  color: #178DFA;
  font-weight: bold;
}

.homeDayNumber:hover {
  background-color: rgb(218, 218, 218);
  border-radius: 5px;
  cursor: pointer;
  padding: 0px 10px;
}

.empty {
  /* visibility: hidden; */
  width: calc(100% / 7);
  border: 1px solid silver; /* Add grid lines for days not in month */
  background-color: #d9d9d99a; /* Make greyish background for days not in month (Before days) */
  border-spacing: 0;
  padding: 5px;
  min-height: 40px;
}

.empty .dayNumber {
  text-align: left;
  color: #178DFA;
  font-weight: bold;
}

.titleCard{
  border: 0.5px solid rgb(174, 174, 174);
  padding: 1px 2px;
  border-radius: 5px;
}

.eventContainer {
  font-size: small;
}

.calendarWrapperContainer .viewModeOptions {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  background-color: white;
  position: sticky;
  top: 0;
  /* border-bottom: 1px solid silver; */
}

.eventContainer .homeEvent{
  margin-bottom: 2px;
  color: blue;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  cursor: pointer;
}

.eventContainer .eventTitle {
  margin-bottom: 2px;
  color: blue;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.eventContainer .eventTitle:hover {
  cursor: pointer;
  font-weight: bold;
}

.eventContainer .eventTitle.upcomingEvent{
  color: blue;
}

.eventContainer .homeTitle.upcomingEvent{
  color: blue;
}

.eventContainer .eventTitle.closingDate{
  color: black;
  font-weight: bold;
}

.eventContainer .homeTitle.closingDate{
  color: black;
}

.eventContainer .eventTitle.completedEvent{
  color: green;
}

.eventContainer .homeTitle.completedEvent{
  color: green;
}

.eventContainer .eventTitle.incompleteEvent{
  color: red;
}

.eventContainer .homeTitle.incompleteEvent{
  color: red;
}

.eventContainer .eventTitle.holiday {
  color: purple;
  font-weight: 600;
}

.eventContainer .homeTitle.holiday{
  color: purple;
  font-weight: 600;
}

.seeMore {
  cursor: pointer;
  color: orangered;
}

.homeTitle:hover {
  font-weight: 600;
}

.calendarWrapperContainer .printBtn {
  /* position: sticky;
  top: 0;
  right: 10px;
  left: 93%;
  margin-left: 5px; */
}

@media screen and (min-width: 551px) and (max-width: 850px) {
  .calendar {
    width: 90%;
  }
 
  .header .headerTools .arrow {
    font-size: small;
  }
}

@media screen and (max-width: 480px) {
  .calendarWrapperContainer .printBtn {
    display: none;
  }
 
  .header .headerTools .arrow {
    font-size: small;
  }
}

@media screen and (max-width: 550px) {
  .calendarWrapperContainer {
    zoom: 0.7;
  }
  .calendar {
    width: 98%;
  }
  
  .calendarWrapperContainer .shareBtnInWebView {
    font-size: smaller;
  }
  .header .headerTools .arrow {
    font-size: small;
  }
}
