.DoraDocumentContainer {
    width: 100%;
}
.DoraDocumentContainer .doraDocHeading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.DoraDocumentContainer .doraDocHeading>h3 {
    color: #189EFF;
    font-size: 24px;
}
.legendTableOuterDiv {
    margin-top: 20px;
    height: 70vh;
    overflow-y: auto;
}
.legendTableOuterDiv>table {
    width: 100%;
    font-weight: 600;
    border-collapse: collapse;
}
.legendTableOuterDiv>table th,
.legendTableOuterDiv>table td {
    border: .5px solid silver;
    border-collapse: collapse;
    padding: 5px 10px;

}

.legendTableOuterDiv>table thead {
    background-color: #3871b9;
    color: white;
    text-align: center;
    font-size: 18px;
}


.fileContainer:hover{
    color: #6e99d6;
    cursor:pointer;
}


/* legends css starts */
.tableRow {
    height: 50px;
    width: 100%;
    /* border: 2px solid green; */
  }
  
  .symbolsColumn {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    /* border: 1px solid red; */
  }
  
  .infoColumn {
    width: 80%;
    text-align: left;
  }
  
  .topHalfCircle {
    width: 20px;
    height: 10px;
    border-radius: 10px 10px 0 0;
    border: 1px solid black;
    overflow: hidden;
    position: relative;
  }
  
  .topHalfCircle::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid black;
    border-radius: 10px 10px 0 0;
  }
  
  .bottomHalfCircle {
    width: 20px;
    height: 10px;
    border-radius: 0 0 10px 10px;
    border: 1px solid black;
    overflow: hidden;
    position: relative;
  }
  
  .bottomHalfCircle::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10px;
    border-top: 2px solid black;
    border-radius: 0 0 10px 10px;
  }
  
  .fullCircle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid black;
  }
  
  
   /* legend css ends */

@media screen and (min-width: 551px) and (max-width: 850px) {
    .DoraDocumentContainer .doraDocHeading>h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 550px) {
    .DoraDocumentContainer .doraDocHeading>h3 {
        font-size: 18px;
    }
    .legendTableOuterDiv>table thead {
        font-size: 14px;
    }

    .legendTableOuterDiv>table tbody {
        font-size: 12px;
    }

}
