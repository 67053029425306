@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
@media print {
  html, body {
    background-color: #ffffff;
    font-size: 10pt !important;
    line-height: 1.2em !important;
  }
  @page {
    size: letter;
  }

  .no-print, nav, #pan1 {
    visibility: hidden;
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  div:not(.signature-block):not(.no-block),
  .print {
    display: block !important;
    max-width: 100% !important;
    width: 100% !important;
    padding-top: 3px !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    overflow-y: visible !important;
  }

  span[contenteditable],
  input,
  textarea {
    background-color: white !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none !important;
  }

  #pan2 {
    margin: 0 !important;
    padding: 0 !important;
  }

  #AGENT_HEADER {
    /* display: flex !important;
    gap: 20px;
    align-items: center; */
    margin-bottom: 10px;
  }
  .form-wrapper .titleOrderForm .titleCompanyLogoAndName,
  .form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable,
  .form-wrapper .titleOrderForm .titleCompanyDetailContainer .DoubleSidedTransactionContainer {
    display: flex !important;
  }

  .form-wrapper .titleOrderForm .titleCompanyLogoAndName {
    margin-bottom: 10px !important;
  }

  .form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable {
    zoom: 0.9;
  }
  .form-from-html{
    font-family: Arial, Helvetica, sans-serif, 'Times New Roman' !important;
  }
  .form-from-html .form-wrapper .mt {
    margin-top: 1rem !important;
  }

  .form-from-html .form-wrapper .mt2 {
    margin-top: 2rem !important;
  }
  .form-from-html .form-wrapper .signature-block {
    page-break-inside: avoid;
  }

  .form-wrapper .SeparatorVerticalLine {
    display: none !important;
    visibility: hidden !important;
  }
}


.form-from-html {
  overflow-x: hidden;
  
  /* FOR IOS MAKING CSS COMPATIBLE */
  /* -webkit-text-size-adjust: 0; */
  /* -webkit-text-size-adjust: auto; */
  -webkit-text-size-adjust: initial;
}

.form-from-html body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-from-html{
  font-family: Arial, Helvetica, sans-serif, 'Times New Roman' !important;
  font-size: 10pt !important;
}

/* ************************title order css starts********************** */

.form-wrapper .titleOrderForm .sectionTitle {
  background-color: #B4D4FF;
  padding: 5px 0;
}

.form-wrapper .titleOrderForm .titleCompanyLogoAndName {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}

/* .form-wrapper .titleOrderForm .titleCompanyLogoAndName .logoContainer {
  width: 100%;
  text-align: center;
} */

.form-wrapper .titleOrderForm .titleCompanyLogoAndName .logoContainer,
.form-wrapper #transactionReport .CompanyAddressTable .logoContainer {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: end;
}

.form-wrapper .titleOrderForm .titleCompanyLogoAndName .logoContainer .TitleCompanyLogo {
  width: 30%
}

.form-wrapper #transactionReport .CompanyAddressTable .logoContainer .agentCompanyLogo {
  min-width: 60px;
  max-width: 100px;
}

.form-wrapper .titleOrderForm .titleCompanyLogoAndName .nameContainer {
  width: 100%;
  text-align: center;
  font-size: x-large;
}
.form-wrapper .titleOrderForm .titleCompanyLogoAndName .dummyContainer {
  width: 100%;
}
.form-wrapper .titleOrderForm .titleCompanyLogoAndName .nameContainer input {
  color: inherit;
  background-color: inherit;
}
.form-wrapper .titleOrderForm .titleCompanyLogoAndName .nameContainer input:focus {
  outline: none;
}
.form-wrapper .titleOrderForm .titleCompanyLogoAndName .nameContainer input::placeholder {
  font-size: inherit;
}

.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 30px;
}

.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable.titleCompanyDetailTable input,
.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable.titleCompanyDetailTable textarea {
  background-color: #f0f7fd;
  color: blue;
}

.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .rightTable,
.form-wrapper #transactionReport .CompanyAddressTable .rightTable {
  width: 25%;
  /* width: 30%; */
}
.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .leftTable,
.form-wrapper #transactionReport .CompanyAddressTable .leftTable {
  width: 25%;
  /* width: 30%; */
}
.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .listingTableContainer,
.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .sellingTableContainer {
  width: 100%;
}

.form-wrapper .titleOrderForm .titleCompanyDetailContainer .titleCompanyAddressTable .listingSellingSeparatorVerticalLine {
  position: absolute;
  border-left: 1px solid;
  /* color: orange; */
  color: #B4D4FF;
  left: 50%;
  top: 0;
  height: 100%;
}

.form-wrapper .titleOrderForm .tableWidth {
  width: auto;
  display: inline;
}

.form-wrapper .titleOrderForm .tableWidth2 {
  width: 100%;
  /* display: inline; */
  /* outline: auto; */
}

.form-wrapper .titleOrderForm .tableWidth3,
.form-wrapper .titleOrderForm .tableWidth3 td,
.form-wrapper #transactionReport .tableWidth3,
.form-wrapper #transactionReport .tableWidth3 td,
.form-wrapper #transactionReport .tableWidth3 th
 {
  border: 1px solid;
  border-collapse: collapse;
  border-color: silver;
}

.form-wrapper .titleOrderForm input:not(.noWidth,input[type='checkbox']),
.form-wrapper .titleOrderForm textarea,
.form-wrapper #transactionReport input:not(.noWidth,input[type='checkbox']),
.form-wrapper #transactionReport textarea {
  width: 100%;
}

.form-wrapper .titleOrderForm input::placeholder,
.form-wrapper .titleOrderForm textarea::placeholder,
.form-wrapper #transactionReport input::placeholder,
.form-wrapper #transactionReport textarea::placeholder {
  font-weight: 100;
  font-size: small;
  color: rgb(170, 168, 168);
}

.form-wrapper .titleOrderForm #titleCompanySection .nbb{
  border: 0 !important;
}

/* ************************title order css ends********************** */

/* ************************Transaction report css starts********************** */

.form-wrapper #transactionReport .sectionTitle {
  background-color: #B4D4FF;
  padding: 5px 0;
}
.form-wrapper #transactionReport .CompanyAddressTable {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 30px;
}

.form-wrapper .SeparatorVerticalLine {
  position: absolute;
  border-left: 1px solid;
  /* color: orange; */
  color: #B4D4FF;
  left: 50%;
  top: 0;
  height: 100%;
}
.form-wrapper #transactionReport .CompanyAddressTable .listingTableContainer,
.form-wrapper #transactionReport .CompanyAddressTable .sellingTableContainer {
  width: 100%;
}

.form-wrapper #transactionReport #emailCommunicationsSection .list-container {
  display: none;
}

.form-wrapper #transactionReport #emailCommunicationsSection .dropdown-icon {
  margin-left: 20px;
  text-align: left;
  display: inline-block;
}
.form-wrapper #transactionReport #emailCommunicationsSection .dropDownContent:hover {
  background-color: #95d3ce;
}
.form-wrapper #transactionReport #emailCommunicationsSection .subjectColumn {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Display ellipsis for overflow */
  max-width: 0; /* So that td width should not increase */
  min-width: 200px;
}
.form-wrapper #transactionReport #emailCommunicationsSection .expanded .subjectColumn {
  white-space: normal; /* Override white-space to display full text */
  overflow: visible; /* Override overflow */
  text-overflow: initial; /* Override text-overflow */
  max-width: 0; /* So that td width should not increase */
  word-wrap: break-word;
}
.form-wrapper #transactionReport textarea:disabled,
.form-wrapper #transactionReport input[type='date']:disabled {
  background-color: #ececec;
  color: black;
}
.form-wrapper #transactionReport .nbb{
  border: 0 !important;
}
.form-wrapper #transactionReport .noBg{
  background-color: inherit;
  color: inherit;
}



/* ************************Transaction report css ends********************** */

.form-from-html .form-wrapper {
  /* margin: 2rem 2em; */
  padding: 2rem 2rem;
  /* padding: 32px 32px; */
  overflow-x: auto;
  width: -webkit-fill-available;
  max-width: 1000px;
  background-color: white;
  margin: auto;
}

input.noAutoSave::placeholder {
  color: rgb(170, 168, 168);
}

.form-wrapper .clickable:hover {
  cursor: pointer;
}


.form-wrapper .bg-grey {
  background-color: lightgray;
}

.form-wrapper .double-border {
  padding: 0.4rem 0;
  /* padding: 6px 0; */
  border-width: 5px;
  border-style: double;
}

.form-wrapper .fssm {
  font-size: 0.9em;
  /* font-size: 14px; */
}

.form-wrapper .fslg {
  font-size: 1.2em;
  /* font-size: 18px; */
}
.form-wrapper .fsxlg {
  font-size: 1.5em;
}

.form-wrapper .removed {
  text-decoration: line-through;
  display: none !important;
}

.form-wrapper input[type="checkbox"] {
  margin: 0px 5px;
  cursor: context-menu !important;
}

.form-wrapper input[type="number"].currency,
.form-wrapper input[type="text"].currency {
  /* min-width: 90%; */
  background-color: #f0f7fd;
}

.form-wrapper input[type="number"]:disabled,
.form-wrapper input[type="text"]:disabled {
  background-color: #ececec;
}

.form-wrapper input.no-edit {
  background-color: white !important;
}

.form-wrapper input[type="date"],
.form-wrapper input[type="time"],
.form-wrapper input[type="number"],
.form-wrapper input[type="email"],
.form-wrapper input[type="text"] {
  border: 0;
  border-bottom: 1px solid black !important;
  font-family: inherit;
  font-size: inherit;
  background-color: #f0f7fd;
  font-weight: bold;
  color: blue;
  cursor: text !important;
}
.form-wrapper textarea {
  cursor: text !important;
}
i {
  text-indent: 0;
}

.form-wrapper span[contenteditable] {
  text-decoration: underline black;
  background-color: #f0f7fd;
  color: blue;
  font-weight: bold;
  display: inline;
  text-indent: 0;
}

.form-wrapper span[contenteditable]:empty,
.form-wrapper span[contenteditable]:focus {
  display: inline-block;
}

.form-wrapper span[contenteditable="false"]:empty {
  display: inline-block;
  background-color: #ececec;
  min-height: 1em;
  /* min-height: 16px; */
}

.form-wrapper span[contenteditable]:empty:not(.noul) {
  border-bottom: 1px solid black;
}

.form-wrapper span[contenteditable].ta-like {
  /* min-width: 95%; */
  min-width: 950px;
}

.form-wrapper span[contenteditable].ta-like:focus {
  min-height: 3em;
  /* min-height: 48px; */
}

.form-wrapper span[contenteditable].ta-like:empty:not(.noul) {
  min-height: 3em;
  /* min-height: 48px; */
  border-bottom: 1px solid black;
}

.form-wrapper .noul {
  text-decoration: none !important;
}

.form-wrapper .border-bottom-input,
.form-wrapper input[type="text"]:not(.nb),
.form-wrapper input[type="date"]:not(.nb),
.form-wrapper input[type="time"]:not(.nb),
.form-wrapper input[type="number"]:not(.nb) {
  border: 0;
  border-bottom: 1px solid black !important;
}

.form-wrapper .border-around {
  border: 1px solid black;
  padding: 2px;
}

.form-wrapper .nb {
  border: 0 ;
}

.form-wrapper .is {
  text-indent: 20pt;
  /* text-indent: 26px; */
}

.form-wrapper .id {
  text-indent: 48pt;
  /* text-indent: 64px; */
}

.form-wrapper .it {
  text-indent: 60pt;
  /* text-indent: 80px; */
}

.form-wrapper .il {
  text-indent: 95pt;
}

.form-wrapper .tc {
  text-align: center;
}

.form-wrapper .tl {
  text-align: left;
}

.form-wrapper .tr {
  text-align: right;
}

.form-wrapper .tb {
  font-weight: bold !important;
}

.form-wrapper .ti {
  font-style: italic;
}

.form-wrapper .ua {
  font-weight: bold !important;
  color: blue !important;
}

.form-wrapper .relativeDateInput, .fw{
  font-weight: bold;
  color: blue;
  background-color: #f0f7fd;
  width: -webkit-fill-available;
}

.form-wrapper table.wb input.currency[type="text"] {
  text-align: right;
  min-width: 100px;
}

/* .form-wrapper input.currency[type="text"] {
  text-align: right;
  padding-right: 2px;
  min-width: 100px;
} */

.form-wrapper .tu {
  text-decoration: underline;
  text-decoration-color: black;
}

.form-wrapper .mt {
  margin-top: 1rem;
  /* margin-top: 16px; */
}
.form-wrapper .mt2 {
  margin-top: 2rem;
  /* margin-top: 32px; */
}
.form-wrapper .mt3 {
  margin-top: 3rem;
  /* margin-top: 48px; */
}
.form-wrapper .mb {
  margin-bottom: 1rem;
  /* margin-bottom: 16px; */
}
.form-wrapper .ml {
  margin-left: 1rem;
  /* margin-left: 16px; */
}
.form-wrapper .mr {
  margin-right: 1rem;
  /* margin-right: 16px; */
}
.form-wrapper .vh {
  visibility: hidden;
}
.form-wrapper .w100 {
  width: 100%;
}
.form-wrapper .w95 {
  width: 95%;
}
.form-wrapper .bi-pencil-square, .bi-trash3-fill, .bi-trash3 {
 margin-left: 5px;
}
/* .form-wrapper .page-break {
  page-break-before: always;
} */
.form-wrapper .mwfull:empty,
.form-wrapper .mwfull:focus {
  width: 100% !important;
}
.form-wrapper .bdi:empty,
.form-wrapper .mwfull:focus {
 border: 0 !important;
 /* border-bottom: 0px solid red !important; */
 /* background: #fff; */
}

.form-wrapper .mwxl:empty,
.form-wrapper .mwxl:focus {
  min-width: 300px !important;
}

.form-wrapper .mwlg:empty,
.form-wrapper .mwlg:focus {
  min-width: 200px !important;
}

.form-wrapper .mwmd:empty,
.form-wrapper .mwmd:focus {
  min-width: 100px !important;
}

.form-wrapper .mwsm:empty,
.form-wrapper .mwsm:focus {
  min-width: 25px !important;
}

.form-wrapper table.wb,
.form-wrapper table.wb th,
.form-wrapper table.wb td {
  border: 1px solid black;
  border-collapse: collapse;
}

.form-wrapper table {
  width: 100%;
}

.form-wrapper td,
.form-wrapper th {
  padding-left: 2pt;
  padding-right: 2pt;
  /* padding-left: 3px; */
  /* padding-right: 3px; */
}

.form-wrapper table.address {
  border: none !important;
}


.form-wrapper .remove-section {
  color: red;
}

/* [data-signature-block] div:nth-child(odd) {
  width: 45%;
  margin-right: 4.5%;
}

[data-signature-block] div:nth-child(even) {
  width: 45%;
  margin-left: 4.5%;
} */

.form-wrapper [data-signature-block] td {
  border: 0;
}

.form-wrapper .grow-with-content {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.form-wrapper .grow-with-input {
  display: inline-grid;
  text-indent: 0;
}

.form-wrapper .grow-with-content::after,
.form-wrapper .grow-with-input::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.form-wrapper .grow-with-content > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
  cursor: text;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.form-wrapper .grow-with-content > textarea,
.form-wrapper .grow-with-content::after {
  /* Identical styling required!! */
  border: 0;
  padding: 0.25em;
  /* padding: 4px; */
  background-color: #f0f7fd;
  font-weight: bold;
  color: blue;
  font-size: inherit;
  font-family: inherit;
  break-inside: avoid;

  grid-area: 1 / 1 / 1 / 1;
}

.form-wrapper .grow-with-content > textarea:not(.nm),
.form-wrapper .grow-with-content:not(.nm)::after {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* margin-top: 8px;
  margin-bottom: 8px; */
  border: 1px solid black;
}

.form-wrapper .grow-with-input > input,
.form-wrapper .grow-with-input::after {
  /* Identical styling required!! */
  background-color: #f0f7fd;
  font-weight: bold;
  color: blue;
  white-space: pre-wrap;
  font-size: inherit;
  font-family: inherit;
  /* Place on top of each other */
  grid-area: 1 / 1 / 1 / 1;
  padding-right: 0;
  padding-left: 0;
  margin-left: 5px;
}

.form-wrapper #AGENT_HEADER {
  display: flex;
  gap: 20px;
  align-items: center;
}

.form-wrapper #agentOfficeLogo {
  margin: 5px 10px 10px 5px;
}

.form-from-html .nav .section {
  margin: 10px 10px 10px 15px;
  font-size: 1.2em;
  /* font-size: 16px; */
  font-weight: 400;
  /* font-family: sans-serif; */
  /* padding: 5px 5px; */
  /* line-height: 30px; */
  padding: 0.3em;
  line-height: 1.1em;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-variant: all-small-caps;
  background: rgb(18, 78, 143);
  border: 2px solid rgb(40, 119, 196);
  border-radius: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-wrapper .signature-wrapper {
  height: 60px;
  display: inline-flex;
  border-radius: 5px;
  background-color: #b6dfdc;
}

.form-wrapper .signature-img,
.form-wrapper .signature-verification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  /* padding: 12px; */
}

.form-wrapper .signature-img img {
  max-width: 250px;
  max-height: 55px;
}

.form-wrapper .signature-verification {
  background-color: #95d3ce;
  color: darkblue;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.form-wrapper .laol {
  padding-inline-start: 1em;
  /* padding-inline-start: 16px; */
}

.form-wrapper .laol li {
  /* margin-top: 4px;
  padding-inline-start: 16px; */
  margin-top: 0.25em;
  padding-inline-start: 1em;
}

.form-wrapper .bb1 {
  border-bottom: 1px solid;
}

.form-wrapper .bb3 {
  border-bottom: 3px solid black;
}

.form-wrapper .active-signer {
  background-color: cyan;
  height: 50px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.form-wrapper .inactive-signer {
  background-color: #cdcdcd;
  height: 50px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-wrapper .active-signer::after {
  content: "Sign Here";
  font-size: 24px;
}

.form-wrapper .inactive-signer::after {
  content: "";
  font-size: 24px;
}

.strikeThrough {
  background: repeating-linear-gradient( 180deg, red 0%, red 100% );
  background-size: 100% 2px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}

.form-wrapper #sellerBrokerNameEditIcon {
  color: blue;
  position: absolute;
  top: 3px;
  left: -20px;
  display: none;
}
.form-wrapper .add-broker-btn {
  display: inline-block;
    /* padding: 10px 20px; */
    padding: 5px 10px;
    margin: 10px 0;
    font-size: 16px;
    color: #fff;
    /* background-color: #007bff; */
    background-color: #1890ff;
    /* border-color: #1890ff; */
    border: none;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.form-wrapper .add-broker-btn:hover {
  background-color: #0056b3;
}

.form-wrapper .add-broker-btn:active {
  background-color: #004085;
}

.form-wrapper .grow-with-input > input[type="number"] {
  appearance: none !important;
  -moz-appearance: textfield !important;
}

.form-wrapper .grow-with-input > input[type="number"]::-webkit-outer-spin-button,
.form-wrapper .grow-with-input > input[type="number"]::-webkit-inner-spin-button {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

@media screen and (min-width: 1001px) {
  .withoutNav .form-wrapper {
    width: 80%;
    background-color: white;
  }
}
@media screen and (max-width: 1000px) and (min-width: 501px) {
  .form-wrapper {
    zoom: 0.8;
    width: 100%;
  }
  .withoutNav .form-wrapper {
    background-color: white;
  }
  .form-wrapper .active-signer::after {
    content: "Sign Here";
    font-size: 20px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 500px) {
  .form-wrapper {
    zoom: 0.4;
    width: 100%;
    min-width: 600px;
    overflow: hidden;
  }
  .nav {
    /* font-size: 10px; */
    /* margin: 10px 0px; */
    font-size: 0.6rem;
    margin: 0.6rem 0px;
  }
  .withoutNav .form-wrapper {
    background-color: white;
  }
  .form-wrapper .clauseDiv {
    /* margin-bottom: 32px; */
    margin-bottom: 2rem;
  }
  .form-wrapper .active-signer::after {
    content: "Sign Here";
    font-size: 16px;
    white-space: nowrap;
  }
  #pan1 {
    visibility: hidden;
  }
}
