.eventName {
    font-size: 15px;
    text-align: center;
}

.modalDateTitle {
    text-align: center;
    color: #178DFA;
    text-transform: uppercase;
}

.mainDetailContainer {
    background-color: #60bf40;
    color: aliceblue;
    padding: 10px;
    border-radius: 10px;
}

.fullAddress {
    margin-top:-5px;
    color: #178DFA;
    font-weight:bold;
    font-size: 18px;
    text-align: center;
    cursor: default;
}

/* .fullAddress:hover{
    color: black;
} */

.modalFullAddressHyperLink{
    margin-top:-5px;
    font-weight:bold;
    font-size: 18px;
    text-align: center;
    color: blue; 
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 1.5px;
  }
  .modalFullAddressHyperLink:hover{
    color: #551A8B;
  }

.modalContents {
    font-weight: bold;
    color: rgba(11, 123, 203);
    margin-bottom: 5px;
}

.eventDates {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 550px) {

    .modalDateTitle,
    .modalContents {
        font-size: small;
    }

    .fullAddress,
    .modalFullAddressHyperLink {
        font-size: medium;
       
    }

    .eventName {
        font-size: smaller; 
    }

    .description {
        font-size: smaller;
    }
}
