.OIformWrapper textarea {
  width: 100%;
}
.OIformWrapper .grow-with-content {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.OIformWrapper .grow-with-content::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.OIformWrapper .grow-with-content > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
  cursor: text;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.OIformWrapper .grow-with-content > textarea,
.OIformWrapper .grow-with-content::after {
  border: 0;
  padding: 0.25em;
  /* background-color: #f0f7fd;
  font-weight: bold;
  color: blue;
  font-size: inherit;
  font-family: inherit; */
  break-inside: avoid;

  grid-area: 1 / 1 / 1 / 1;
}

.OIformWrapper .grow-with-content > textarea:not(.nm),
.OIformWrapper .grow-with-content:not(.nm)::after {
  /* margin-top: 0.5rem;
  margin-bottom: 0.5rem; */
  border: 1px solid black;
}
