.showImagePdfModal .ant-modal-body {
    padding: 5px;
}
.showImagePdfModal .ant-modal-close-x{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iframePreRtd {
    touch-action: manipulation; /* Allow pinch zooming on iOS */
}

@media screen and (max-width: 550px) {
    .showImagePdfModal .ant-modal-body {
        padding: 0px;
    }
    .showImagePdfModal .ant-modal-close-x{
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}