.demoloadlisting {
  color: #143b7c;
}

.clientListItem {
  cursor: pointer;
}

.useravtar {
  background-color: #000;
  width: 40px;
  height: 40px;
}

.clientListItem:hover {
  background-color: rgba(42, 82, 196, 0.1);
  /* color: #fff !important; */
}

.formcreate label {
  font-size: 16px !important;
  font-weight: 600;
}

.formcreate label:before {
  position: absolute;
  right: -14px;
}

.formcreate label.item-required:not(.item-required-mark-optional)::before {
  margin-right: 0;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  right: -10px;
  position: absolute;
}

.createtitle {
  font-size: 26px;
  font-weight: 600;
  padding: 0px 0 8px 0;
  /* border-bottom: 2px solid #f0f0f0; */
  margin-bottom: 11px;
}
