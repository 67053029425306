#parent-scroll-div .ant-list-item-meta-description {
  margin-top: -5px;
}
#parent-scroll-div .ant-list-item-meta-title {
  margin-top: -1px;
}

.ant-tooltip-inner {
  background-color: #ffcc00 !important;
  color: #333 !important;
}
/* .ant-radio-wrapper {
  display: flex;
  align-items: center;
}

.ant-radio-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-radio-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1890ff;
}

.ant-radio-inner:after {
  background-color: #1890ff;
} */




/* custom stylinig for phoneInput placeholder */
.phoneInput::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5715;
}
.page {
  background-color: white;
  position: relative;
  z-index: 0;
}
.vector {
  position: absolute;
  z-index: 1;
}
.image {
  position: absolute;
  z-index: 2;
}

.withNavSignbutton {
  position: fixed !important;
  bottom: 23%;
  left: 24%;
  background-color: #fffc00 !important;
  color: #f40000 !important;
  padding: 1px 9px !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px !important;
  border: none !important;
  z-index: 1;
}

.withoutNavSignbutton {
  position: fixed !important;
  bottom: 23%;
  left: 10%;
  background-color: #fffc00 !important;
  color: #f40000 !important;
  padding: 1px 9px !important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px !important;
  border: none !important;
  z-index: 1;
}

@media (max-width: 1000px) {
  .withNavSignbutton {
    left: 0%;
  }
  .withoutNavSignbutton {
    left: 0%;
  }
}

.withoutNavSignbutton::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 19px solid #fffc00;
  border-bottom: 19px solid transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
}

.withNavSignbutton::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 19px solid #fffc00;
  border-bottom: 19px solid transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
}

input[type="date"] {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
/* input[type="time"] */
/* input[type="time"]::-webkit-calendar-picker-indicator  */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none !important;
}

.ant-table-tbody > tr > td {
  position: relative;
  padding: 8px 8px;
  overflow-wrap: break-word;
}

.zoom_button_1 {
  position: fixed;
  top: 70%;
  right: 1.5%;
  z-index: 11;
}
.zoom-plus-button_1 {
  background-color: white;
  cursor: pointer;
  border-radius: 50%;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  height: 50px;
  /* opacity: 0.67; */
}

.zoom-minus-button_1 {
  background-color: white;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 50%;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  height: 50px;
  /* opacity: 0.67; */
}
.zoom-icons {
  width: 50px;
  height: 50px;
}

.zoom-plus-button_1:hover #zoom-plus-color {
  fill: #004aff;
}

.zoom-plus-button_1 #zoom-plus-color {
  fill: #84c1fa;
}


.zoom-minus-button_1:hover #zoom-minus-color {
  fill: #004aff;
}

.zoom-minus-button_1 #zoom-minus-color {
  fill: #84c1fa;
}


/* CSS for mobile devices */
@media (max-width: 768px) {
  .zoom_button_1 {
    /* position: fixed !important;
    height: 20px;
    bottom: 5vh;
    right: 5vw;
    z-index: 161; */
    display: none;
  }
}
.table-skeleton td,
.table-skeleton thead,
.table-skeleton tbody,
.table-skeleton tr,
.table-skeleton {
  border: 1px solid black;
}

.ant-col-12 {
  max-width: inherit !important;
  margin-right: 30px;
}

.check {
  padding: 0px !important;
}

.dots-3 {
  width: 10px;
  margin: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: d5 1s infinite linear alternate;
}
@keyframes d5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}
/* transaction report css starts */
.tr-property-name {
  background-color: #085191;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.tr-container {
  margin: 3px;
  padding: 10px;
  width: 70%;
  margin: auto;
}
.tr-heading {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.tr-buyers-container {
  margin-top: 1%;
  border-radius: 15px;
  font-size: 16px;
}
.tr-sellers-container,
.tr-agency-container,
.tr-documents-container,
.tr-dates-container {
  margin-top: 1%;
  margin-bottom: 3%;
  border-radius: 15px;
  font-size: 16px;
}
.tr-sellers-container,
.tr-agency-container,
.tr-buyers-container {
  padding-left: 20px;
}
.tr-sellers-container {
  padding-left: 30px !important;
}
@media print {
  .tr-sellers-container,
  .tr-agency-container,
  .tr-buyers-container {
    padding-left: 0px;
  }
  .tr-sellers-container {
    padding-left: 0px !important;
  }
  .tr-container {
    margin: 3px;
    padding: 10px;
  }
}
.tr-sellers-container > table,
.tr-agency-container > table,
.tr-documents-container > table,
.tr-dates-container > table {
  width: 980px;
}
.tr-container table td,
.tr-container table th {
  padding-left: 5px;
  border: 1px solid black;
}
.tr-container table thead {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tr-sellers-section,
.tr-agency-section {
  margin-top: 10px;
}
.tr-a-buyer {
  margin: 1%;
  border-radius: 15px;
  width: 480px;
}
.tr-a-buyer > div,
.tr-a-seller > div,
.tr-listing-co > div,
.tr-selling-co > div {
  text-align: left;
  margin-left: 3%;
}
.tr-a-seller,
.tr-listing-co,
.tr-selling-co {
  margin: 1%;
  border-radius: 15px;
  width: 480px;
}
.tr-a-seller > div {
  margin: 1%;
}
.fields-tr {
  height: 27px;
  width: 140px;
}
.field-values-tr {
  margin-left: 3%;
  width: 350px;
  height: 27px;
  color: #004aff !important;
  font-weight: 500;
  /* border-bottom: 2px solid black; */
}
.rtd-table-tr,
.rtd-table-tr-sd {
  width: 70%;
  margin: auto;
  padding: 20px 0px 20px;
}
.rtd-table-tr tr,
.rtd-table-tr-sd tr {
  height: 50px;
}
.rtd-table-tr thead,
.rtd-table-tr-sd thead {
  background-color: #085191;
  color: white;
}
.rtd-table-tr tr > td:nth-child(1) {
  width: 100px;
}
.rtd-table-tr tr > td:nth-child(2) {
  width: 500px;
}
.rtd-table-tr tr > td:nth-child(3) {
  width: 100px;
}
.rtd-table-tr tr > td:nth-child(4) {
  width: 100px;
}

h3.ant-typography,
.ant-typography h3 {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 22px !important;
  text-transform: uppercase !important;
}

.lbl1 label {
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}




/* *******new form css************* */
#rpanrResize {
  display: flex;
  width: 100%;
  height: calc(100vh - 00px);
  flex-grow: 0;
  flex-shrink: 0;
  background: #7d7d80;
  width: 0.5rem;
  cursor: col-resize;
}
.reSizerWrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 00px);
}

#pan1 {
  display: flex;
  width: 100%;
  height: calc(100vh - 00px);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%; /* initial status */
  overflow: hidden;
  padding-top: 10vh;
}
#pan2 {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
  overflow-x: auto;
  margin-top: 10vh;
}
#rpanrResize .CCVOpenCloseBtn {
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #124E8F;
  color: #fff;
  font-size: 20px;
  border-end-end-radius: 10px;
  border-end-start-radius: 10px;
  border: none;
  font-weight: 500;
  position: relative;
  z-index: 10;
  left: -20px;
}

.commonModalTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  color: #2697FF;
  text-transform: capitalize;
}


/* Media query for screens above 1000px width */
@media screen and (min-width: 1001px) {
  #rpanrResize {
    flex-grow: 0;
    flex-shrink: 0;
    width: 0.5rem;
    cursor: col-resize;
  }
  .reSizerWrapper {
    display: flex;
    width: 100%;
  }

  #pan2 {
    display: flex;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    overflow-x: auto;
    justify-content: center;
  }
}

/* Media query for screens below 1000px width */
@media screen and (max-width: 1000px) {
  #pan1 {
    display: flex;
    width: 100%;
    /* height: calc(100vh - 00px); */
    flex-grow: 1;
    flex-shrink: 2;
    flex-basis: 0%; /* initial status */
    overflow: hidden;
  }
  #rpanrResize {
    display: flex;
    border: none;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    background: #fff;
    width: 0.5rem;
    cursor: col-resize;
  }
  
}

@media screen and (max-width: 500px) {
  #pan1 {
    flex-shrink: 0;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    visibility: hidden;
    z-index: 2;
  }
  @keyframes sidebar-load {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);

    }
  }

  @keyframes sidebar-unload {

    0% {
        transform: translateX(0);

    }

    100% {
        transform: translateX(-100%);
    }
  }

  #rpanrResize .CCVOpenCloseBtn {
    font-size: 14px;
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;
    font-weight: 700;
    left: -20px;
  }
  #pan2 {
    margin-top: 8vh;
  }
  
  .commonModalTitle{
    font-size: 18px;
  }
}

