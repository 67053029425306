.ant-drawer-content-wrapper{
    width: 500px !important;
}

/* Make the arrows darker */
.ant-picker-header .ant-picker-header-super-prev-btn,
.ant-picker-header .ant-picker-header-prev-btn,
.ant-picker-header .ant-picker-header-next-btn,
.ant-picker-header .ant-picker-header-super-next-btn {
  color: #4f4f4f !important; /* Darker color for the arrows */
}

@media (min-width: 320px) {
    .ant-drawer-content-wrapper{
        width: 310px !important;
    }
  }

  @media (min-width: 360px) {
    .ant-drawer-content-wrapper{
        width: 350px !important;
    }
  }

  @media (min-width: 375px) {
    .ant-drawer-content-wrapper{
        width: 370px !important;
    }
  }

  @media (min-width: 411px) {
    .ant-drawer-content-wrapper{
        width: 400px !important;
    }
  }

  @media (min-width: 480px) {
    .ant-drawer-content-wrapper{
        width: 470px !important;
    }
  }
  @media (min-width: 600px) {
    .ant-drawer-content-wrapper{
        width: 400px !important;
    }
  }
