#templateNameDiv {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  /* border: 2px solid red; */
}
#templateNameDiv > input {
  padding: 5px 10px;
  /* border-radius: 10px; */
}
#templateDescriptionDiv {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  height: 100px;

  /* border: 2px solid red; */
}
#templateDescriptionDiv > input {
  padding: 10px;
  border-radius: 10px;
}

.ant-dropdown {
  position: fixed !important;
  /* top: 38px; */
}

.navOptions {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}


@media screen and (max-width:1000px) and (min-width:501px) {
  .navOptions {
    font-size: small;
  }
}

@media screen and (max-width:500px) {
  .navOptions {
    font-size: 10px;
  }
 
}

.custom-spinner .ant-spin-dot {
 background-color: transparent; 
}

.custom-spinner .ant-spin-dot-item {
  background-color: greenyellow; 
}
