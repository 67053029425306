@media print {
    .noPrint {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
}

.EmailPageOuterContainer {
    margin: 0;
    padding: 0;
    position: relative;
}

.emailScreenContainer {
    padding-top: 10vh;
    background-color: aliceblue;
}

.outerBox {
    min-height: 90vh;
    width: 60%;
    background-color: white;
    margin: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1rem 2rem;
}

.outerBox .subject {
    font-size: large;
    color: grey;
    font-weight: bold;
}

.outerBox .date {
    font-size: small;
    color: rgb(164, 161, 161);
    text-align: right;
}

.outerBox table .label {
    font-weight: bold;
    vertical-align: text-top;
}

.outerBox table .value {
    padding-left: 10px;
}

.outerBox .emailBody {
    width: 100%;
    margin-top: 1rem;
}

.outerBox .emailAttachments {
    margin-top: 1rem;
}

/* To make image responsive */
.outerBox .emailBody img,
.outerBox .emailAttachments img {
    max-width: 100% !important;
}

@media screen and (min-width: 551px) and (max-width: 850px) {

    .outerBox {
        width: 80%;
    }

    .outerBox .subject {
        font-size: medium;
    }

    .outerBox table .label,
    .outerBox table .value {
        font-size: small;
    }

    .outerBox .emailBody,
    .outerBox .emailAttachments {
        font-size: small;
    }
}

@media screen and (max-width: 550px) {

    .emailScreenContainer {
        padding-top: 7vh;
    }

    .outerBox {
        width: 90%;
    }

    .outerBox .subject {
        font-size: medium;
    }

    .outerBox .date {
        font-size: 10px;
    }

    .outerBox table .label,
    .outerBox table .value {
        font-size: small;
    }

    .outerBox .emailBody,
    .outerBox .emailAttachments {
        font-size: small;
    }

}