.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  height: 5em !important;
}

.fc-popover{
  position: absolute;
  z-index: 20 !important;
}

.fc-popover-body {
    max-height: 70svh;
    overflow-y: auto;
}

.EventDetailModal .ant-modal-close-x {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Customize Tooltip background color */
.customTooltip .ant-tooltip-inner {
  background-color: #FAFAD2 !important; /* Green background */
  border: 1px solid rgb(216, 216, 216);
}

@media (min-width: 800px) {
  .rbc-toolbar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .bm-burger-button {
    position: fixed !important;
    width: 36px !important;
    height: 30px !important;
    left: 140px !important;
    top: 50px !important;
  }
}
@media (max-width: 801px) {
  .rbc-toolbar {
    display: grid;
    justify-content: center;
    text-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 15px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 250px !important;
  top: 0px !important;
}

/* General sidebar styles */
.bm-menu {
  background: #fdfdfd;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
.bm-menu a {
  color: #b8b7ad;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0px !important;
}

/* calender design */

.rbc-header {
  white-space: normal !important;
  padding: 0 0 !important;
  /* border: 1px solid #474747 !important; */
}

.rbc-header > button {
  background: #ffffff;
  /* background: #373a47; */
  font-weight: 800;
  border: none;
}

.rbc-timeslot-group {
  /* border-bottom: 2px solid #474747 !important; */
}

.rbc-time-content {
  /* border-top: 2px solid #474747 !important; */
}

.rbc-time-content > * + * > * {
  /* border-left: 1px solid #474747 !important; */
}

.rbc-day-slot .rbc-time-slot {
  /* border-top: 1px solid #474747 !important; */
}
.rbc-time-header-content {
  /* border: 1px solid #474747 !important; */
}
.rbc-day-bg + .rbc-day-bg {
  /* border-top: 1px solid #474747 !important; */
}

/* .rbc-today {
  background-color: #474747 !important;
}

.rbc-time-header-cell > .rbc-today > button {
  background-color: #5c1515 !important;
} */

.rbc-day-bg + .rbc-day-bg {
  /* border-left: 1px solid #474747 !important; */
}

.rbc-date-cell {
  color: #0089ff !important;
}
.rbc-off-range {
  color: #999999 !important;
}

/* .rbc-button-link{
  pointer-events: none;
} */

.rbc-time-view {
  width: 100% !important;
  overflow-x: scroll !important;
}

.rbc-time-header {
  width: 137% !important;
  /* overflow: hidden !important; */
}

.rbc-time-content {
  width: 137% !important;
  overflow: visible !important;
}

.rbc-allday-cell {
  display: none !important;
}

:root {
  --height-of-slot: 10vh;
  --width-of-slot: 20vw;
}

.rbc-time-gutter {
  position: fixed;
  top: 81px;
  left: 30px;
  margin-bottom: 50px;
  z-index: 18;
  height: 88vh !important;
  min-height: 0;
  flex-flow: column nowrap;
  background-color: #fcfdff;
  overflow-y: scroll !important;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.rbc-time-header-cell {
  position: fixed;
  top: 51px;
  left: 163px;
  overflow-x: scroll !important;
  min-width: 88vw important;
  flex-flow: row nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.rbc-header {
  min-width: var(--width-of-slot);
}
.rbc-time-view {
  margin-top: 30px;
}
.rbc-time-gutter::-webkit-scrollbar,
.rbc-time-header-cell::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.rbc-timeslot-group {
  min-height: var(--height-of-slot) !important;
}

.rbc-time-gutter > .rbc-timeslot-group {
  border: none;
}
.rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
  min-height: var(--height-of-slot) !important;
}
.rbc-time-header-gutter {
  position: fixed;
  top: 5;
  background-color: #fcfdff;
}
.rbc-header {
  min-width: var(--width-of-slot);
}
.rbc-day-slot .rbc-time-slot {
  min-width: var(--width-of-slot);
}

.fc-timegrid-slot {
  height: 1px !important;
  border-bottom: 0 !important;
}
.fc-agenda-slots td div {
  height: 20px;
}
.fc-timegrid-slot-minor {
  line-height: 0.8;
}

@media screen and (max-width:400px) {
    .fc .fc-toolbar-title{
        font-size: 1rem !important;
    }

    .fc .fc-button .fc-icon{
      position: relative;
      top: -5px;
    }

    .fc-timegrid-event .fc-event-main{
        height: 5px !important;
        padding: 0% !important;
    }
    .fc-v-event .fc-event-main {
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }
}

@media screen and (max-width: 680px) {
    .fc .fc-toolbar-title{
        font-size: 1rem !important;
    }

    .fc .fc-button .fc-icon{
      position: relative;
      top: -5px;
    }

    .fc-timegrid-event .fc-event-main{
        height: 5px !important;
        padding: 0% !important;
    }
    .fc-v-event .fc-event-main {
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }
    }
