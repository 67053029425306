.licenseTable {
  width: 100%;
  margin: 0px auto 20px auto;
}

.licenseTable tr>th {
  background-color: #2171b9;
  color: white;
  text-align: center;
}

.licenseTable th:first-child {
  border: none;
  border-radius: 5px 0 0 0px;
}

.licenseTable th:last-child {
  border: none;
  border-radius: 0px 5px 0px 0px;
}

.licenseTable tbody {
  border-radius: 0 0 5px 5px;
}

.licenseTable tr:last-child td:first-child {
  border-bottom-right-radius: 5px;
}

.licenseTable tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.licenseTable tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
 .omdheading{
display: flex; align-items: center;
 justify-content: center;
 color:white;
    font-size: 1.8rem
 } 

 .licenseTable td, th {
  border: 1px solid #ddd;
}

.licenseTable th {
  text-align: center;
  padding: 0.25rem 0.5rem;
}
.licenseTable td {
  padding: 0.25rem 0.5rem;
}
.licenseTable tr {
  height: 40px;
}
.offertableheight{
  border-top-left-radius:15px;
    overflow:hidden;
  height:74vh;
  
}
.rateAlignment {
  margin: 0 !important;
  font-size: 20px !important;
}
.numberHeader{
  align-items: center;
  justify-content:space-between;
  display:flex;
  padding-left:20px;
  padding-right:20px
 }

.singleColOfferTable{
  width: 50%;
margin:auto
  
}
.doubleColOfferTable{
  width: 70%;
  margin:auto
 }
 .threeColOfferTable{
  width: 80%;
  margin:auto
 }

input[type="text"][disabled] {
  color: black;
}
/* th:first-child {
  border:none;
  border-radius:8px 0 0 0px;

}
th:last-child {
  border:none;
  border-radius:0px 8px 0px 0px;
} */


.rateAlignment {
  margin: 0 !important;
  font-size: 15px !important;
}

.textAlignment {
  font-size: 20px;
}

.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 8px 8px;
  overflow-wrap: break-word;
}
.offerTableNavbar {
  min-width: 100vw;
  height: 14vh;
  box-shadow: 0px 1px 5px 0px #00000040;

  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0px 80px;
  /* border: 2px solid green; */
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  background: #fff;
}
.tableWidth {

  padding-top:20px;
  z-index: 2;
}

.backButton{
  margin-top:-20px;
  margin-left:-30px;
  cursor:pointer}
.toolBarSecondCol {
  width: 100%;
  display: grid;
  gap:100px;
  /* grid-template-columns: 0.5fr 2fr 2fr 0.5fr; */
}
.navbarSection1 {
  flex: 1; 
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: flex-start;

  width:100%
 
}
.navbarSection2 {
  flex: 1; 
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: flex-end;
  padding-top: 5px;
  margin-left:-20px;
 
  gap: "20px";
   
}
.helpicon{
  text-align:left;
  margin-right:-20px;
  margin-top: -5px;
  
  }
  .menuIcon{
    font-weight: bold;
    font-size: 30px;
    margin-right:-30px ;
    text-align: right;
    margin-top:-5px
  }
  .legend{
    font-size: 25px;
    cursor: pointer;
    font-family:bold;
    padding-top: 5px;
    /* color: black; */
  

  }
.propertyimage{
 width: 100%; 

 height: 100px;
 border-radius:5px;
 color:rgb(251, 249, 249)
}

.prorertyAddress{

font-size: 20px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.6px;
text-align: left;

  max-width:283px;
 
  margin-left: -15px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 49px;
  /* line-height: 29.5px; */
 
 
}


.firstColAlignment {
  background-color: #F2F2F2 !important;
  /* box-shadow: 0px 0px 4px 0px #00000040; */

max-width: 250px;
min-width: 250px;
 text-transform: capitalize;
  text-align: center;
position: relative;
border:1px solid lightgray;
border-right: 5px solid #F2F2F2;
  top: 0;
  left: 0;
 
font-size: 20px;
font-weight: 600;
line-height: 24px;
margin-bottom: 16px;

letter-spacing: 0.12999999523162842px;
text-align: center;


}

.otherColAlignment {
 max-width: 270px;
 min-width: 270px;
 height:70px;
  border-left: 5px solid #F2F2F2; /* Add a left border */
  border-right: 5px solid #F2F2F2;
 
  font-weight: 400;
  font-size: 18px;
  
  
  
}
.otherColhiddenAlignment {
  max-width: 270px;
  min-width: 270px;
  height:70px;
   border-left: 5px solid #F2F2F2; /* Add a left border */
   border-right: 5px solid #F2F2F2;
   font-weight: 400;
   font-size: 18px;
   color:darkgray;
   background-color:rgb(251, 249, 249);
 }
.flex{
  display: flex;
  margin-left: 20px;
  margin-right:-20px;
  align-items: center;
  margin-bottom: 10px;
  
 
}
.offerNumber{
  display:flex;
  margin-top:5px;
  margin-left:-15px;
  background:#71BB66;
  text-align:center;
  justify-content:center;
align-items:center;
  border-radius:5px;
 
  font-size:15px;
  color:white;
  width:127px;
  gap:10px;
  box-shadow:0px 1px 5px 0px #00000040;
  height:25px;
  padding:"1px 8px 1px 8px";
  }
  .import{
    font-size: 25px;
    /* cursor: pointer; */
    font-family:bold;
    padding-top: 5px;
    /* color: black; */
  
  }
  .customscrollbar {
    height: 500px;
    overflow: auto;
    scrollbar-color: #2171b9; /* Change the thumb and track color for Firefox */
  }
  
  /* Custom scrollbar style for Firefox */
  .customscrollbar::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  .customscrollbar::-webkit-scrollbar-thumb {
    background: #2171b9; /* Change the thumb color */
  }
  
  .customscrollbar::-webkit-scrollbar-track {
    background: gray; /* Change the track color */
  }
  .legendtext{
    text-decoration: underline;
   cursor: pointer; 
    font-size: 20px; 
    font-weight: 600;
     margin-left: 10px
    
  }
 
.criteria{
  text-transform:capitalize;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
 
  display: flex;
  width:100%;
  justify-content: space-between;
 
    /* align-items: flex-start */
  
}
.offertablenumber{
font-weight: 600;
 display:flex;
  align-items: center;
   font-size: 22px;
   color:#fff;
    margin-top: -5px
}
  .heart{
    animation: grow 2s ease infinite;
  }
  @keyframes  grow {
    from { transform: scale(0); }
    to { transform: scale(1); }
  }
.header{
 width: 100vw; 
 height: 55px;
  background: rgb(8, 81, 145);
    padding: 0px 25px;
    position:sticky ;
    top: 0; left: 0;
    z-index:10;
    
}
.checkPoint{
  width: 30px;
   height: 30px;

}
.menuitemicon{
    width: 30px;
    height: 30px;
    font-weight: 600;
    margin-left: 5px;
    font-size: 26px;
    color:  white,
  
}
.headername {
  color: white;
  font-size: 1.4rem;
  max-width: 300px;
  font-weight: 500;
  white-space: nowrap; /* Corrected */
  text-overflow: ellipsis;
  overflow: hidden;
}
.headerlogo{
 width:100px;
 height:50px
}
.omdname{
  font-weight: 500;
}

.eyeIcon:hover {
  color: #178DFA;
}

@media only screen and (max-width: 1366px) {
  .header{
    width: 100vw; 
    height: 45px;
     background: rgb(8, 81, 145);
       padding: 0px 25px;
       position:sticky ;
       top: 0; left: 0;
       z-index:10;
       
   }
   .omdname{
    font-weight: 500;
  }
  .headername {
    color: white;
 
    font-size: 1rem;
    max-width: 300px;
    font-weight: 500;
    white-space: nowrap; /* Corrected */
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .omdheading{
    display: flex; align-items: center;
     justify-content: center;
     color:white;
        font-size: 1.2rem;

     } 
     .headerlogo{
      width:70px;
      height:35px
     }
    
}
@media screen and (max-device-width: 1024px) {
  .offertableheight{
    overflow:auto;
    height:83vh;
   }
  .offerTableNavbar {
   height: 10vh;
  }
  .singleColOfferTable{
    width: 70%;
  margin:auto
    
  }
  .doubleColOfferTable{
    width: 100%;
    margin:auto
   }
   .threeColOfferTable{
    width: 100%;
    margin:auto
   }
  
}
@media screen and (max-device-width: 600px) {
  .singleColOfferTable{
    width: 90%;
  margin:auto
    
  }
}
  /* <-----------------mobile screen-view(portrait)---------------> */
  @media screen and (max-device-width: 480px) 

    and (orientation: portrait) {
      .omdname{
        font-weight: 500;
        visibility: hidden;
      }
      .headername {
        color: white;
       font-size: 1rem;
        max-width: 300px;
        font-weight: 500;
        white-space: nowrap; /* Corrected */
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .offertable {
        zoom: 0.65;
       
      } 
      .headerlogo{
        width:70px;
        height:35px
       }
      .tableWidth {

        padding:0px;
        z-index: 2;
      }
      .offerTableNavbar {
        min-width: 100vw;
        height: 120px;
        box-shadow: 0px 1px 5px 0px #00000040;
       
        /* overflow: hidden; */
        display: inline-block;
        align-items: center;
        margin: 0;
        padding: 10px 0px 0px 0px;
        /* border: 2px solid green; */
        box-sizing: border-box;
        position: sticky;
        top: 40px;
        left: 0;
        bottom:0;
        z-index: 9;
        background:#fff;
      }
     
      .prorertyAddress{
         font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.6px;
        text-align: left;
        max-width:230px;
        margin-left: -15px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height:49px;
          /* line-height: 29.5px; */
         
         
        }
        .backButton{
          display: flex;
        margin-top: -35px;
          margin-left:5px;
          cursor:pointer
        }
        .navbarSection1 {
          flex: 1; 
          display: flex;
          align-items: center;
          gap: 2rem;
          justify-content: flex-start;
     
          width:100%
         
        }
.offerNumber{
  display:flex;
  margin-top:5px;
  margin-left:-15px;
  background:#71BB66;
  text-align:center;
  justify-content:center;
  align-items:center;
  border-radius:5px;
  font-size:12px;
  color:white;
  max-width:100px;
  gap:3px;
  box-shadow:0px 1px 5px 0px #00000040;
  height:25px;
  padding:"1px 3px 1px 3px";
  }
  .navbarSection2 {
   
    height:30px;
    flex: 1; 
    display: flex;
    align-items: center;
    justify-content:space-around;
    
 padding-left:10px;
 padding-right: 30px;
    padding-top: -10px;
    margin-left:0px;

  
     
  }   
   .legendtext{
    text-decoration: underline;
   cursor: pointer; 
    font-size: 16px; 
    font-weight: 600;
     margin-left: 10px
    
  }
  .helpicon{
    font-size: 1px;
    text-align:left;
    margin-right:-20px;
    margin-top: -5px;
    
    }
    .menuIcon{
      font-weight: bold;
      font-size: 25px;
      margin-right:-30px ;
      text-align: right;
      margin-top:-5px
    }
    .import{
      font-size: 20px;
      /* cursor: pointer; */
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
    }
    .legend{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
  
    }
    
.singleColOfferTable{
  width: 100%;
margin:auto
  
}
.doubleColOfferTable{
  width: 100%;
  margin:auto
 }
 .threeColOfferTable{
  width: 100%;
  margin:auto
 }

  .offertableheight{
  border-top-left-radius:15px;
    overflow:auto;
height:auto
  
} 
.criteria{

  text-transform:capitalize;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  display: flex;
  

}
.firstColAlignment {
  background-color: #F2F2F2 !important;
  /* box-shadow: 0px 0px 4px 0px #00000040; */

max-width: 200px;
min-width: 200px;
 text-transform: capitalize;
  text-align: center;
position: relative;
border:1px solid lightgray;
border-right: 5px solid #F2F2F2;
  top: 0;
  left: 0;
 
font-size: 15px;
font-weight: 600;
line-height: 24px;
margin-bottom: 16px;

letter-spacing: 0.12999999523162842px;
text-align: center;


}
.otherColAlignment {
  max-width: 200px;
  min-width: 200px;
  height:85px;
   border-left: 5px solid #F2F2F2; /* Add a left border */
   border-right: 5px solid #F2F2F2;
  
   font-weight: 400;
   font-size: 15px;
   
   
   
 }
 .otherColhiddenAlignment {
   max-width: 200px;
   min-width: 200px;
   height:85px;
    border-left: 5px solid #F2F2F2; /* Add a left border */
    border-right: 5px solid #F2F2F2;
    font-weight: 400;
    font-size: 15px;
    color:darkgray;
    background-color:rgb(251, 249, 249);
  }
  .offertablenumber{
    font-weight: 600;
     display:flex;
      align-items: center;
       font-size: 15px;
       color:#fff;
        margin-top: -5px
    }
    .header{
      width: 100vw; 
      height: 40px;
       background: rgb(8, 81, 145);
         padding: 0px 25px;
         position:sticky ;
         top: 0; left: 0;
         z-index:10
     }
     .omdheading{
      visibility: hidden;
      display: flex; align-items: center;
       justify-content: center;
       color:white;
          font-size: 15px
       }
  
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 1080px) 
    and (orientation: landscape) {
       .offertable {
        zoom: 0.75;
       
      } 
      .omdheading{
        display: flex;
        padding-top: 5px;
         align-items: center;
         justify-content: center;
         color:white;
            font-size: 15px
         }
      .tableWidth {

        padding:5px;
        z-index: 2;
      }
   
      .offerTableNavbar {
        min-width: 100vw;
        height: 75px;
        box-shadow: 0px 1px 5px 0px #00000040;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin: 0;
        width:100%;
        padding: 0px 40px  ;
        /* border: 2px solid green; */
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        z-index: 9;
        background: #fff;
      }
      /* .propertybox{
        display: flex;
        
      } */
      .prorertyAddress{
        font-size: 16px;
    
       font-weight: 400;
       line-height: 20px;
       letter-spacing: 0.6px;
       text-align: left;
       max-width:200px;
      margin-left: -15px;
       font-weight: 400;
       overflow: hidden;
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       max-height:49px;
       }
      
        .legendtext{
          text-decoration: underline;
          cursor: pointer; 
          font-size: 16px; 
          font-weight: 600;
           margin-left: 10px
          
        }
        
  .helpicon{
    font-size: 1px;
    text-align:left;
    margin-right:-20px;
    margin-top: -5px;
    
    }
    .menuIcon{
      font-weight: bold;
      font-size: 20px;
      margin-right:-30px ;
      text-align: right;
      margin-top:-5px
    }
    .import{
      font-size: 20px;
      /* cursor: pointer; */
      font-family:bold;
      padding-top: 5px;
      color: black;
    
    }
    .legend{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
  
    }
    .propertyimage{
      width: 100%; 
     
      height: 60px;
      border-radius:5px;
      color:rgb(251, 249, 249)
     }
         
.singleColOfferTable{
  width: 50%;
margin:auto
  
}
.doubleColOfferTable{
  width: 70%;
  margin:auto
 }
 .threeColOfferTable{
  width: 100%;
  margin:auto
 }
 .offertablenumber{
  font-weight: 600;
   display:flex;
    align-items: center;
     font-size: 15px;
     color:#fff;

  }
  .criteria{

    text-transform:capitalize;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    display: flex;
    /* justify-content: center;
    align-items: flex-start */
  
  }
  .firstColAlignment {
    background-color: #F2F2F2 !important;
    /* box-shadow: 0px 0px 4px 0px #00000040; */
  
  max-width: 200px;
  min-width: 200px;
   text-transform: capitalize;
    text-align: center;
  position: relative;
  border:1px solid lightgray;
  border-right: 5px solid #F2F2F2;
    top: 0;
    left: 0;
   
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
  
  letter-spacing: 0.12999999523162842px;
  text-align: center;
  
  
  }
  .otherColAlignment {
    max-width: 200px;
    min-width: 200px;
    height:85px;
     border-left: 5px solid #F2F2F2; /* Add a left border */
     border-right: 5px solid #F2F2F2;
    
     font-weight: 400;
     font-size: 15px;
     
     
     
   }
   .otherColhiddenAlignment {
     max-width: 200px;
     min-width: 200px;
     height:85px;
      border-left: 5px solid #F2F2F2; /* Add a left border */
      border-right: 5px solid #F2F2F2;
      font-weight: 400;
      font-size: 15px;
      color:darkgray;
      background-color:rgb(251, 249, 249);
    }
    .navbarSection1 {
      flex: 1; 
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: flex-start;

      width:100%
     
    }
.offerNumber{
display:flex;
margin-top:5px;
margin-left:-15px;
background:#71BB66;
text-align:center;
justify-content:center;
align-items:center;
border-radius:5px;
font-size:12px;
color:white;
max-width:100px;
gap:3px;
box-shadow:0px 1px 5px 0px #00000040;
height:20px;
padding:"1px 3px 1px 3px";
}
    .navbarSection2 {
   max-width: 40%;
      flex: 1; 
      display: flex;
      align-items: center;
      /* gap: 2rem; */
      justify-content: flex-end;
      padding-top: 5px;
       gap: "5px";
    
    }
    .offertableheight{
      border-top-left-radius:15px;
        overflow:auto;
      height:100%;
      
    }
    .header{
      width: 100vw; 
      height: 40px;
       background: rgb(8, 81, 145);
         padding: 0px 25px;
         position:sticky ;
         top: 0; left: 0;
         z-index:10
     }
}

/* ----- css for ios device ----- */
@media only screen and (-webkit-touch-callout: none) and (orientation: portrait) {

  .omdheading{
    display: flex; align-items: center;
     justify-content: center;
     color:white;
     visibility: hidden;
        font-size: 15px
     }
      .offertable {
        zoom: 0.65;
       
      } 
      
      .tableWidth {

        padding:0px;
        z-index: 2;
      }
      .offerTableNavbar {
        min-width: 100vw;
        height: 120px;
        box-shadow: 0px 1px 5px 0px #00000040;
       
        /* overflow: hidden; */
        display: inline-block;
        align-items: center;
        margin: 0;
        padding: 10px 0px 0px 0px;
        /* border: 2px solid green; */
        box-sizing: border-box;
        position: sticky;
        top: 40px;
        left: 0;
        bottom:0;
        z-index: 9;
        background:#fff;
      }
     
      .prorertyAddress{
         font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.6px;
        text-align: left;
        max-width:230px;
        margin-left: -15px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height:49px;
          /* line-height: 29.5px; */
         
         
        }
        .backButton{
          display: flex;
        margin-top: -35px;
          margin-left:5px;
          cursor:pointer
        }
        .navbarSection1 {
          flex: 1; 
          display: flex;
          align-items: center;
          gap: 2rem;
          justify-content: flex-start;
     
          width:100%
         
        }
.offerNumber{
  display:flex;
  margin-top:5px;
  margin-left:-15px;
  background:#71BB66;
  text-align:center;
  justify-content:center;
  align-items:center;
  border-radius:5px;
  font-size:12px;
  color:white;
  max-width:100px;
  gap:3px;
  box-shadow:0px 1px 5px 0px #00000040;
  height:25px;
  padding:"1px 3px 1px 3px";
  }
  .navbarSection2 {
   
    height:30px;
    flex: 1; 
    display: flex;
    align-items: center;
    justify-content:space-around;
    
 padding-left:10px;
 padding-right: 30px;
    padding-top: -10px;
    margin-left:0px;

  
     
  }   
   .legendtext{
    text-decoration: underline;
    
    cursor: pointer; 
    font-size: 16px; 
    font-weight: 600;
     margin-left: 10px
    
  }
  .helpicon{
    font-size: 1px;
    text-align:left;
    margin-right:-20px;
    margin-top: -5px;
    
    }
    .menuIcon{
      font-weight: bold;
      font-size: 25px;
      margin-right:-30px ;
      text-align: right;
      margin-top:-5px
    }
    .import{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      color: black;
    
    }
    .legend{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
  
    }
    
.singleColOfferTable{
  width: 100%;
margin:auto
  
}
.doubleColOfferTable{
  width: 100%;
  margin:auto
 }
 .threeColOfferTable{
  width: 100%;
  margin:auto
 }

  .offertableheight{
  border-top-left-radius:15px;
    overflow:auto;
height:auto
  
} 
.criteria{

  text-transform:capitalize;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  display: flex;
  

}
.firstColAlignment {
  background-color: #F2F2F2 !important;
  /* box-shadow: 0px 0px 4px 0px #00000040; */

max-width: 200px;
min-width: 200px;
 text-transform: capitalize;
  text-align: center;
position: relative;
border:1px solid lightgray;
border-right: 5px solid #F2F2F2;
  top: 0;
  left: 0;
 
font-size: 15px;
font-weight: 600;
line-height: 24px;
margin-bottom: 16px;

letter-spacing: 0.12999999523162842px;
text-align: center;


}
.otherColAlignment {
  max-width: 200px;
  min-width: 200px;
 
   border-left: 5px solid #F2F2F2; /* Add a left border */
   border-right: 5px solid #F2F2F2;
  
   font-weight: 400;
   font-size: 15px;
   
   
   
 }
 .otherColhiddenAlignment {
   max-width: 200px;
   min-width: 200px;
 
    border-left: 5px solid #F2F2F2; /* Add a left border */
    border-right: 5px solid #F2F2F2;
    font-weight: 400;
    font-size: 15px;
    color:darkgray;
    background-color:rgb(251, 249, 249);
  }
  .offertablenumber{
    font-weight: 600;
     display:flex;
      align-items: center;
       font-size: 15px;
       color:#fff;
        margin-top: -5px
    }
    .header{
      width: 100vw; 
      height: 40px;
       background: rgb(8, 81, 145);
         padding: 0px 25px;
         position:sticky ;
         top: 0; left: 0;
         z-index:10
     }
  

}

@media only screen and (-webkit-touch-callout: none) and (orientation: landscape) 
   {
    .omdheading{
      display: flex; align-items: center;
       justify-content: center;
       color:white;
       padding-top: 5px;
          font-size: 15px
       }
       .offertable {
        zoom: 0.75;
       
      } 
       
      .tableWidth {

        padding:5px;
        z-index: 2;
      }
   
      .offerTableNavbar {
        min-width: 100vw;
        height: 75px;
        box-shadow: 0px 1px 5px 0px #00000040;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin: 0;
        width:100%;
        padding: 0px 40px  ;
        /* border: 2px solid green; */
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        z-index: 9;
        background: #fff;
      }
      /* .propertybox{
        display: flex;
        
      } */
      .prorertyAddress{
        font-size: 16px;
    
       font-weight: 400;
       line-height: 20px;
       letter-spacing: 0.6px;
       text-align: left;
       max-width:150px;
       margin-left: -15px;
       font-weight: 400;
       overflow: hidden;
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       max-height:49px;
       }
      
        .legendtext{
          text-decoration: underline;
          cursor: pointer; 
          font-size: 16px; 
          font-weight: 600;
           margin-left: 10px
          
        }
        
  .helpicon{
    font-size: 1px;
    text-align:left;
    margin-right:-20px;
    margin-top: -5px;
    
    }
    .menuIcon{
      font-weight: bold;
      font-size: 20px;
      margin-right:-30px ;
      text-align: right;
      margin-top:-5px
    }
    .import{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      color: black;
    
    }
    .legend{
      font-size: 20px;
      cursor: pointer;
      font-family:bold;
      padding-top: 5px;
      /* color: black; */
    
  
    }
    .propertyimage{
      width: 100%; 
     
      height: 60px;
      border-radius:5px;
      color:rgb(251, 249, 249)
     }
         
.singleColOfferTable{
  width: 50%;
margin:auto
  
}
.doubleColOfferTable{
  width: 70%;
  margin:auto
 }
 .threeColOfferTable{
  width: 100%;
  margin:auto
 }
 .offertablenumber{
  font-weight: 600;
   display:flex;
    align-items: center;
     font-size: 15px;
     color:#fff;

  }
  .criteria{

    text-transform:capitalize;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    display: flex;
    /* justify-content: center;
    align-items: flex-start */
  
  }
  .firstColAlignment {
    background-color: #F2F2F2 !important;
    /* box-shadow: 0px 0px 4px 0px #00000040; */
  
  max-width: 220px;
  min-width: 220px;
   text-transform: capitalize;
    text-align: center;
  position: relative;
  border:1px solid lightgray;
  border-right: 5px solid #F2F2F2;
    top: 0;
    left: 0;
   
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
  
  letter-spacing: 0.12999999523162842px;
  text-align: center;
  
  
  }
  .otherColAlignment {
    max-width: 220px;
    min-width: 220px;
  
     border-left: 5px solid #F2F2F2; /* Add a left border */
     border-right: 5px solid #F2F2F2;
    
     font-weight: 400;
     font-size: 15px;
     
     
     
   }

   .otherColhiddenAlignment {
     max-width: 220px;
     min-width: 220px;
     
      border-left: 5px solid #F2F2F2; /* Add a left border */
      border-right: 5px solid #F2F2F2;
      font-weight: 400;
      font-size: 15px;
      color:darkgray;
      background-color:rgb(251, 249, 249);
    }
    .navbarSection1 {
      flex: 1; 
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: flex-start;

      width:100%
     
    }
.offerNumber{
display:flex;
margin-top:5px;
margin-left:-15px;
background:#71BB66;
text-align:center;
justify-content:center;
align-items:center;
border-radius:5px;
font-size:12px;
color:white;
max-width:100px;
gap:3px;
box-shadow:0px 1px 5px 0px #00000040;
height:20px;
padding:"1px 3px 1px 3px";
}
    .navbarSection2 {
   max-width: 40%;
      flex: 1; 
      display: flex;
      align-items: center;
      /* gap: 2rem; */
      justify-content: flex-end;
      padding-top: 5px;
       gap: "5px";
    
    }
    .offertableheight{
      border-top-left-radius:15px;
        overflow:auto;
      height:100%;
      
    }
    .header{
      width: 100vw; 
      height: 40px;
       background: rgb(8, 81, 145);
         padding: 0px 25px;
         position:sticky ;
         top: 0; left: 0;
         z-index:10
     }
}
