.outerContainer {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.outerContainer .selectEmails {
    /* height: 230px; */
    height: 175px;
    border: 1px solid silver;
    /* border-radius: 5px; */
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.outerContainer .selectEmails .emailAddingOptionsContainer {
    display: flex;
    justify-content: space-between;
}

.outerContainer .selectEmails .emailAddingOptionsContainer .manualAddEmailBox {
    width: 95%;
}

.outerContainer .selectEmails .emailAddingOptionsContainer .manualAddEmailBox .formInnerContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.outerContainer .selectEmails .emailAddingOptionsContainer .manualAddEmailBox .formInnerContainer .formLabel {
    font-weight: bold;
}

.outerContainer .selectEmails .emailAddingOptionsContainer .openClientListBtn {
    text-align: center;
    width: 5%;
    font-size: 20px;
    fill: #085191;
    color: #085191;
}

.outerContainer .selectEmails .showSelectedEmails {
    overflow: auto;
    height: 125px;
}

.outerContainer .selectEmails .showSelectedEmails::-webkit-scrollbar {
    width: 5px;
}

.outerContainer .selectEmails .showSelectedEmails a {
    color: blue;
}

.outerContainer .emailContents {
    width: 100%;
}

.outerContainer .emailContents .emailSpace {
    /* outline: 1px solid silver; */
    /* border-radius: 5px; */
    min-height: 175px;
}

.outerContainer .emailContents .emailSpace .reactQuillComp {
    height: 130px;
}

.outerContainer .emailContents .emailSpace textarea {
    width: 100%;
    border: none;
    /* height: 100px; */
    height: 175px;
    padding: 5px;
    resize: none;
}

.outerContainer .emailContents .emailSpace textarea::-webkit-scrollbar {
    width: 5px;
}

.outerContainer .emailContents .emailSpace textarea:focus {
    outline: none;
}

@media screen and (max-width: 551px) {
    
    .outerContainer .selectEmails .emailAddingOptionsContainer .manualAddEmailBox {
        font-size: small;
    }

    .outerContainer .selectEmails .emailAddingOptionsContainer .manualAddEmailBox .formLabel {
        font-size: small;
    }

    .outerContainer .selectEmails .emailAddingOptionsContainer .manualAddEmailBox input {
        font-size: small;
    }

    .outerContainer .selectEmails .emailAddingOptionsContainer .manualAddEmailBox button {
        font-size: small;
    }

    .outerContainer .selectEmails .emailAddingOptionsContainer .openClientListBtn {
        font-size: 16px;
    }

    .outerContainer .selectEmails .showSelectedEmails a {
        font-size: small;
    }

    .emailContents {
        font-size: small;
    }

}
