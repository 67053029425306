  .createHelpForm .bold-label {
    font-weight: bold;
    color: grey;
  }

  .createHelpForm .inner-span{
    color:red !important;
    font-weight:"bolder";
    font-size:18px;
    margin-right: 5px;
  }

 .createHelpForm .ql-toolbar .ql-image {
    display: none;
  }

 .createHelpForm .imgIcon:hover{
     color:"lightblue"
  }

  /* hide scrollbar
 ::-webkit-scrollbar {
    display: none;
  } */

 .createHelpForm .imageIcon{
    position:relative;
    left:400px;
    top:85px;
  }

 .createHelpForm .imageIcon:hover{
    color: #096dd9;
  }

  /* create form css       */

  .createHelpForm .checkbox-select {
    position: relative;
    width: 200px; /* Adjust the width as needed */
    font-family: Arial, sans-serif;
  }

 .createHelpForm .select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color:none;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

 .createHelpForm .selected-options {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

 .createHelpForm .toggle-icon {
    font-size: 12px;
  }

 .createHelpForm .options-dropdown {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: auto;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    z-index: 1;
  }

 .createHelpForm .flexdiv {
    display: flex;
    flex-direction: column;
  }

 .createHelpForm .label-bold{
    font-weight: bold;
  }

  /* tag css  */
 .createHelpForm .tag {
    display: inline-flex;
    align-items: center;
    margin: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #e0e0e0;
    position: relative;
  }

 .createHelpForm .delete-button {
    display: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    margin-left: 4px;
  }

 .createHelpForm .tag:hover .delete-button {
    display: block;
    color: Black; /* Customize the delete button color on hover */
  }

  /* btn css */
 .createHelpForm .custom-button {
    background-color: #1890ff; /* Ant Design primary color */
    color: #fff; /* Text color */
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    /* font-size: 16px; */
    cursor: pointer;
    transition: background-color 0.3s ease;

    /* Hover state */
    &:hover {
      background-color: #40a9ff; /* Lighter shade on hover */
    }

    /* Active state */
    &:active {
      background-color: #096dd9; /* Slightly darker shade on click */
    }
  }

  /* Input container */
.createHelpForm .input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Input field */
.createHelpForm .custom-input {
  width: 100%;
  padding: 4px 6px;
  border: 1px solid #d9d9d9; /* Ant Design input border color */
  /* border-radius: 4px; */
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 14px;
  line-height: 1.5715;
  color: rgba(0, 0, 0, 0.65);
}

/* Input focus state */
.createHelpForm .custom-input:focus {
  border-color: #1890ff; /* Ant Design primary color on focus */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* Placeholder text */
.createHelpForm .custom-input::placeholder {
  color: #bfbfbf; /* Ant Design input placeholder color */
}

/* Disabled input */
.createHelpForm .custom-input[disabled] {
  background-color: #f5f5f5; /* Ant Design disabled input background color */
  cursor: not-allowed;
}

/* Add some margin to the top for proper spacing */
.createHelpForm .input-container + .input-container {
  margin-top: 8px;
}





/* Styling for Custom Ant Design-like Select */
.createHelpForm .custom-select-container {
        width: 265px;
        position: relative;
        z-index: 2;

  }

.createHelpForm .custom-select {
    position: relative;
    width: 70%;
    padding: 4px 6px;
    border: 1px solid #d9d9d9; /* Ant Design input border color */
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    user-select: none;
  }

  .createHelpForm .selected-option {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.65);
  }

  .createHelpForm .select-icon {
    font-size: 16px;
    color: #bfbfbf; /* Ant Design input placeholder color */
  }

  /* Dropdown options */
 .createHelpForm .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; /* Ant Design input border color */
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 500px; /* Adjust the max height as needed */
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .createHelpForm .options {
    /* ... other properties ... */
    background-color: #ffffff !important;
    display: block;
}

 .createHelpForm  .option {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

.createHelpForm .option:hover {
    background-color: #f0f0f0; /* Ant Design option hover background color */
  }

  .createHelpForm .option.selected {
    background-color: #1890ff; /* Ant Design primary color for selected option */
    color: #fff; /* Text color for selected option */
  }

  .createHelpForm .custom-button[disabled] {
    background-color: #f5f5f5; /* Background color for disabled state */
    color: #ccc; /* Text color for disabled state */
    border: 1px solid #d9d9d9; /* Border color for disabled state */
    cursor: not-allowed; /* Change cursor to not-allowed */
    pointer-events: none; /* Disable click events */
  }

