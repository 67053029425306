@media print {
    .noPrint {
        display: none;
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .contactSheetScreen {
        overflow-y: scroll !important;
        height: auto !important;
    }

    .contactSheetContainer {
        margin-top: 0 !important;
    }

    .headerContainer {
        margin-bottom: 10px !important;
    }

    .contactSheetGridDesign {
        border: none !important;
    }

    .contactSheetGridDesign .propertySection {
        border: 2px solid #124E8F;
        border-radius: 5px;
    }

    .contactSheetGridDesign .contactGrid {
        height: auto !important;
    }

    .contactSheetGridDesign .contactGrid .gridScroll {
        /* padding: 0 !important; */
        display: grid !important;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        gap: 10px;
        height: fit-content;
    }

    .contactSheetGridDesign .contactGrid::-webkit-scrollbar {
        width: 0 !important;
    }

    .contactSheetGridDesign .contactGrid #BUYER_AGENT {
        margin-right: 0 !important;
    }
    
    .contactSheetGridDesign .contactGrid #SELLER_AGENT {
        margin-left: 0 !important;
    }
    
    .contactSheetGridDesign .contactGrid .detailsBox {
        page-break-inside: avoid;
        padding-bottom: 10px !important;
    }
    
    .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .value {
        word-break: break-all;
    }

}

.contactSheetScreen {
    overflow: hidden;
}

.contactSheetContainer {
    width: 70%;
    margin: auto;
    /* height: 90vh; */
    margin-top: 9vh;
}

.headerContainer {
    font-size: 20px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}

.headerContainer .emailContactSheet {
    width: 100%;
    font-weight: bold;
    color: orange;
    font-size: 18px;
    text-align: left;
    display: flex;
}

.headerContainer .emailContactSheet:hover {
    color: rgb(221, 151, 20);
    cursor: pointer;
}

.headerContainer .title {
    width: 100%;
    text-align: center;
    color: #189EFF;
    font-weight: bold;
}

.headerContainer .topToolsSection {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.headerContainer .topToolsSection .plusCircle {
    width: 50%;
    font-size: 22px;
    text-align: right;
    fill: #085191;
    color: #085191;
}

.contactSheetGridDesign {
    width: 100%;
    border: 2px solid #124E8F;
    border-radius: 5px;
}

.contactSheetGridDesign .propertySection {
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 2px solid #124E8F;
    padding: 0 10px;

}

.contactSheetGridDesign .propertySection .propertyAddress {
    text-align: center;
}

.contactSheetGridDesign .contactGrid {
    width: 100%;
    height: 75vh;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow-x: hidden;
    display: flex;
    gap: 10px;
}

.contactSheetGridDesign .contactGrid::-webkit-scrollbar {
    width: 5px;
}

.contactSheetGridDesign .contactGrid .gridScroll {
    /* padding: 0 10px; */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 10px;
    width: 100%;
}

.contactSheetGridDesign .contactGrid #BUYER_AGENT {
    margin-right: 10px;
}

.contactSheetGridDesign .contactGrid #SELLER_AGENT {
    margin-left: 10px;
}

.contactSheetGridDesign .contactGrid .detailsBox {
    width: 100%;
    border: 2px solid silver;
    /* border: 2px solid #124E8F; */
    page-break-inside: avoid;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* height: fit-content; */
    padding-bottom: 10px;
}

.contactSheetGridDesign .contactGrid .detailsBox:hover {
    /* background-color: aliceblue; */
    background-color: rgb(255, 244, 225);
}

.contactSheetGridDesign .contactGrid .detailsBox .headerBox {
    display: flex;
    align-items: center;
}

.contactSheetGridDesign .contactGrid .detailsBox .headerBox .tools {
    width: 15%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: right;
}

.contactSheetGridDesign .contactGrid .detailsBox .headerBox .tools .editContact {
    width: 50%;
    cursor: pointer;
    color: orange;
}

.contactSheetGridDesign .contactGrid .detailsBox .headerBox .tools .editContact:hover {
    color: blue;
}

.contactSheetGridDesign .contactGrid .detailsBox .headerBox .tools .deleteContact {
    width: 50%;
    cursor: pointer;
    color: #178DFA;
    text-align: center;
}

.contactSheetGridDesign .contactGrid .detailsBox .headerBox .dummy {
    width: 15%;
}

.contactSheetGridDesign .contactGrid .detailsBox .title {
    width: 70%;
    margin: auto;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: #124e8f;
    cursor: pointer;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails {
    padding: 5px;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails table {
    width: 100%;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails table td {
    border: none;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails .brokerageLogo {
    text-align: left;
    vertical-align: top;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails .image {
    text-align: center !important;
    vertical-align: top;
    padding-bottom: 10px;
}

.flexContainer {
    display: flex;
}

.flexContainer > div {
    flex: 1;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails .image img,
.contactSheetGridDesign .contactGrid .detailsBox .fullDetails .brokerageLogo img {
    max-width: 70px;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails .image .userAvatar {
    width: 50px;
    height: 50px;
    background: #085191;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .label {
    font-weight: bold;
    color: #124e8f;
    width: 20%;
    vertical-align: baseline;
    cursor: pointer;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .value {
    vertical-align: baseline;
    cursor: pointer;
    word-break: break-all;
}

/* .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .value:hover {
    color: #1890ff;
} */

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .value.fullName {
    font-weight: bold;
}

.contactSheetGridDesign .contactGrid .detailsBox .fullDetails table input[type="text"],
.contactSheetGridDesign .contactGrid .detailsBox .fullDetails table input[type="email"],
.contactSheetGridDesign .contactGrid .detailsBox .fullDetails table input[type="tel"] {
    /* background-color: #f0f7fd; */
    border: 1px solid silver;
    border-radius: 5px;
    color: blue;
    font-weight: bold;
    background-color: inherit;
    width: 90%;
}

.contactSheetGridDesign .notesSection {
    cursor: pointer;
}

.contactSheetGridDesign .notesSection .noteLabel {
    font-weight: bold;
    color: orange;
    padding-left: 5px;
}

.contactSheetGridDesign .notesSection .textareaNotes .growWithContent {
    display: grid;
}

.contactSheetGridDesign .notesSection .textareaNotes .growWithContent::after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
    grid-area: 1 / 1 / 1 / 1;
    break-inside: avoid;

}

.contactSheetGridDesign .notesSection .textareaNotes .growWithContent textarea {
    font-weight: bold;
    /* background-color: #f0f7fd; */
    background-color: inherit;
    border: none;
    border-radius: 2px;
    color: blue;
    font-weight: bold;
    width: 100%;
    padding: 0 5px;
    resize: none;
    cursor: text;
    overflow: hidden;
    grid-area: 1 / 1 / 1 / 1;
    break-inside: avoid;

}

/* CSS for AddOrUpdateContact component */
.formContainer {
    max-height: 300px;
    overflow: auto;
}

.formContainer::-webkit-scrollbar {
    width: 5px;
}

@media screen and (min-width: 871px) and (max-width: 1000px) {

    .contactSheetContainer {
        width: 85%;
    }

    .headerContainer {
        font-size: 18px;
    }

    .headerContainer .emailContactSheet {
        font-size: 14px;
    }

    .headerContainer .topToolsSection .plusCircle {
        font-size: 18px;
    }

    .contactSheetGridDesign .propertySection {
        font-size: 16px;
    }

    .contactSheetGridDesign .contactGrid::-webkit-scrollbar {
        width: 3px;
    }

    .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .label,
    .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .value {
        font-size: 14px;
    }

    .contactSheetGridDesign .notesSection {
        font-size: 14px;
    }
}

@media screen and (min-width: 551px) and (max-width: 870px) {

    .contactSheetContainer {
        width: 100%;
        padding: 0 10px;
    }

    .headerContainer {
        font-size: 16px;
    }

    .headerContainer .emailContactSheet {
        font-size: 12px;
    }

    .headerContainer .topToolsSection .plusCircle {
        font-size: 16px;
    }

    .contactSheetGridDesign .propertySection {
        font-size: 14px;
    }

    .contactSheetGridDesign .contactGrid::-webkit-scrollbar {
        width: 3px;
    }

    .contactSheetGridDesign .contactGrid .detailsBox .title {
        font-size: 14px;
    }

    .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .label,
    .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .value {
        font-size: 12px;
    }

    .contactSheetGridDesign .notesSection {
        font-size: 12px;
    }
}

@media screen and (max-width: 551px) {
    .contactSheetContainer {
        width: 100%;
        padding: 0 10px;
        margin-top: 8vh;
    }

    .headerContainer {
        font-size: 12px;
    }

    .headerContainer .emailContactSheet {
        font-size: 10px;
    }

    .headerContainer .topToolsSection .plusCircle {
        font-size: 14px;
    }

    .contactSheetGridDesign .propertySection {
        font-size: 12px;
    }

    .contactSheetGridDesign .contactGrid {
        flex-direction: column;
        gap: 5px;
    }

    .contactSheetGridDesign .contactGrid::-webkit-scrollbar {
        width: 2px;
    }

    .contactSheetGridDesign .contactGrid .gridScroll {
        padding: 0 5px;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
    }

    .contactSheetGridDesign .contactGrid #BUYER_AGENT {
        margin-right: 0px;
    }

    .contactSheetGridDesign .contactGrid #SELLER_AGENT {
        margin-left: 0px;
    }

    .contactSheetGridDesign .contactGrid .detailsBox .title {
        font-size: 12px;
    }

    .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .label,
    .contactSheetGridDesign .contactGrid .detailsBox .fullDetails table .value {
        font-size: 12px;
    }

    .contactSheetGridDesign .notesSection {
        font-size: 12px;
    }
}