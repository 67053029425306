.my-checkbox {
    border: 2px solid #1677FF !important;
    border-radius: 4px !important;
}

.customInput .ant-input::placeholder {
    color: #1677FF !important;
}

.addEmailForm {
    justify-content: left;
    margin: 10px 0 -10px;
}

.flexContatiner {
    display: flex;
    align-items: center;
    gap: 10px;
}

.groupLabel {
    font-size: 18px;
    color: grey;
    font-weight: bold;
}

.bulletPoint,
.dash,
.fullName,
.email {
    font-size: 16px;
}

.signlePersonRow {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 10px;
}

.fullName {
    cursor: context-menu;
    text-transform: capitalize;
}

.email {
    text-decoration: underline;
    color: blue !important;
    cursor: context-menu;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



.bulletPoint {
    margin: 0 8px 0 28px;
    color: grey;
}

.dash {
    font-weight: bold;
    cursor: context-menu;
    margin: 0 5px;
}

.spin {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.readOnly {
    font-size: 14px;
    color: grey;
}

.emailSignature {
    font-size: 16px;
    margin-left: 5px;
}

.documentHeader {
    font-size: 14px;
    color: grey;
}

.subHeader {
    font-size: 14px;
    color: grey;
}

.confirmTitle {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.confirmModalContent {
    margin-top: 5px;
    width: 100%;
    margin-left: -15px;
    overflow-x: scroll;
    max-height: 450px;
    overflow-y: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.confirmModalContent::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}

@media (min-width: 601px) and (max-width: 1024px) {

    .fullName,
    .documentHeader,
    .docName {
        font-size: 14px;
    }

    .email {
        font-size: 14px;
    }

    .recipient,
    .confirmTitle {
        font-size: 14px;
    }

    .groupLabel,
    .dash {
        font-size: 14px;
    }

    .emailSignature {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .addEmailForm {
        margin-top: 15px;
    }

    .fullName,
    .documentHeader,
    .docName {
        font-size: 12px;
    }

    .email {
        font-size: 12px;
    }

    .confirmTitle {
        font-size: 12px;
    }

    .groupLabel {
        font-size: small;
        font-weight: bold;
    }

    .dash {
        font-size: 12px;
    }

    .readOnly {
        font-size: 10px;
    }

    .bulletPoint {
        margin: 0 8px 0 25px;
        color: grey;
    }

    .emailSignature {
        font-size: 12px;
    }

    .subHeader {
        font-size: 12px;
        margin-top: 2px;
    }
}
