.filterModal{
    display: grid;
    grid-template-columns: repeat(2,2fr);
    gap:10px
}

@media only screen and (max-width:560px) {
    .filterModal{
        display: grid;
        grid-template-columns: repeat(1,4fr);
    }
}