.signatureConfirmModal>div {
    border-radius: 7px;
    background-color: #ffffff;
    padding: 0;
    max-width: 70vh;
}

.confirmIPDivContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.contentItems {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}
.confirmButtons {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 30px;
}

@media screen  and (max-width: 500px) {
    .confirmIPDivContents {
        font-size: small;
    }
    .confirmButtons {
        margin-top: 10px;
        gap: 20px;
    }

}