.preRTDNavbarContainer {
    width: 100%;
    height: 60px;
    background-color: rgb(8, 81, 145);
    padding: 0px 25px;
}

.outerFlexRow {
    height: 100%;
    display: flex;
    color: white;
}

.columnFirst {
    display: flex;
    align-items: center;
    /* outline: auto; */
    width: 100%;
}

.columnFirst .backBtnBox {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.columnSecond {
    display: flex;
    align-items: center;
    justify-content: center;
    /* outline: auto; */
    width: 100%;
    font-size: 20px;
}

.columnThird {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.columnThird .outerContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
}

@media screen and (min-width: 551px) and (max-width: 850px) {
    .preRTDNavbarContainer {
        height: 55px;
        padding: 0px 15px;
    }

    .columnFirst .backBtnBox {
        gap: 10px;
    }
    
    .columnSecond {
        font-size: 18px;
    }
}

@media screen and (max-width: 550px) {
    .preRTDNavbarContainer {
        height: 50px;
        padding: 0px 10px;
    }

    .columnFirst .backBtnBox {
        gap: 5px;
    }
    
    .columnSecond {
        font-size: 12px;
    }
}